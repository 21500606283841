import React, { useEffect, useState } from "react";
import {  useNavigate, useParams, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Spinner from "../../ui/Spinner/Spinner";
import moment from "moment";

import EditMedCardForm from "../EditMedCardForm";
import Button from "../../ui/Button/Button";
import api from "../../../../api";

import { getPatientRelations } from "../../../../redux/patients/actions";
import Carousel from "../../ui/Carousel/Carousel";

const MedCard = ({
  patientCard,
  propsRole,
  getPatientRelations,
  relations,
}) => {
  const [edit, setEdit] = useState(false);
  const [searchParams] = useSearchParams()
  const navigate = useNavigate();
  const params = useParams()
  const { t } = useTranslation();

  useEffect(() => {
    getPatientRelations();
  }, []);

  const getOld = (input_date) => {
    const date = new Date(input_date);
    const date_in_ms = date.getTime();

    const beauty_date = date.toLocaleDateString().replace(/\//gi, ".");
    return `${beauty_date} (${moment(date_in_ms).fromNow(true)})`;
  };

  const deleteCardHandler = (cardId) => {
    if (window.confirm(t("deleteConfirm"))) {
      api.patients.deletePatientCard(cardId)
        .then(() => navigate("/"))
        .catch(e => {
        alert(`issue with delete patient card - ${e}`)
      });
    }
  };

  const MedCardField = ({ title = "", value = "", last = false }) => {
    return (
      <div className="medcard-field">
        <div className="title">{title}</div>
        <div className="value">{value}</div>
        {!last && <div className="separator"></div>}
      </div>
    );
  };

  const checkedRole = propsRole === "operator" || propsRole === "insurance-operator";
  const isActualCardData = patientCard?.id === Number(params.card)
  const isNoCards = (params.card === searchParams.get("user")) && !(patientCard && patientCard.id)
  
  ////NOTHING IF PATIENT HAS NO CARDS
  if (isNoCards) return null
  
  //LOADING WHEN OLD INFO EXIST
  if (!isActualCardData) {
    return (
      <div className="loadingBox">
        <Spinner/>
      </div>
    )
  }
  
  return (
    <div className="medcard-info">
      <div className="medcard-content">
        {!edit && (
          <>
            <MedCardField
              title={t("name")}
              value={patientCard.firstName ? patientCard.firstName : ""}
            />
            <MedCardField
              title={t("lastName")}
              value={patientCard.lastName ? patientCard.lastName : ""}
            />
            <MedCardField
              title={t("patronymic")}
              value={patientCard.patronymic ? patientCard.patronymic : ""}
            />
            <MedCardField
              title={t("birthDate")}
              value={patientCard.birthDate ? getOld(patientCard.birthDate) : ""}
            />
            <MedCardField
              title={t("sex")}
              value={
                (patientCard && patientCard.gender === 0 && t("maleSex")) ||
                (patientCard && patientCard.gender === 1 && t("femaleSex")) ||
                ""
              }
            />
            <MedCardField
              title={t("relations")}
              value={patientCard.relationShip && patientCard.relationShip.title}
            />
            {/* <MedCardField
            title="Компания / страховая"
            value={patientCard.company}
          /> */}
            <MedCardField
              title={t("policeNumber")}
              value={patientCard.policeNumber}
            />
            <MedCardField title={t("email")} value={patientCard.email} />
            <MedCardField
              title={t("phoneNumber")}
              value={patientCard.phone}
              last
            />
          </>
        )}
        {edit && (
          <EditMedCardForm
            setEdit={setEdit}
            patientCard={patientCard}
            relations={relations}
          />
        )}
      </div>

      {!edit && checkedRole && (
        <div className="medcard-footer">
          <Button
            text={t("editButton")}
            freeTopSpace
            className="btn editCardButton"
            type="button"
            onClick={() => setEdit(true)}
          />
          <Button
            text={t("deleteButton")}
            className="btn deleteCardButton"
            type="button"
            danger
            onClick={() => {
              deleteCardHandler(patientCard.id);
            }}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  patientCard: state?.patients?.patient,
  relations: state.patients && state.patients.relations,
  patients: state.patients,
});

const mapDispatchToProps = { getPatientRelations };

export default connect(mapStateToProps, mapDispatchToProps)(MedCard);
