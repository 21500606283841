import React from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/uk";
import { Container } from "../../../style/grid";
import Header from "../../components/Header/Header";
import Sider from "../../components/Sider/Sider";
import { NetworkErrorPopup } from "../../components/ui/NetworkErrorPopup/NetworkErrorPopup";
import { useEffect } from "react";
import { wsConnected, wsDisconnected } from "../../../redux/chat/actions";
import { roleIs } from "../../../helpers/functions";

const Home = ({
  wsConnected,
  wsDisconnected,
  role,
}) => {
  
  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng")?.slice(0, 2) || "uk";
    moment.locale(lang);
    
    wsConnected()
    return () => wsDisconnected()
  }, [])

  return (
    <main>
      <Container className="mainRowBox">
        <Sider role={role}/>
        <div className="content">
          <Header role={role}/>
          <NetworkErrorPopup />
          <div className="contentContainer">
            <Outlet/>
          </div>
        </div>
      </Container>
    </main>
  );
};
const mapStateToProps = ({ user, patients }) => ({
  user,
  patientCard: patients && patients.patientCard,
});

export default connect(mapStateToProps, { wsConnected, wsDisconnected })(Home);
