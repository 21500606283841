import rootReducer from ".";
import { applyMiddleware, compose, createStore } from "redux";
import {thunk} from "redux-thunk";
import newSocketMiddleware from "./chat/socket/middleware";

export const configureStore = () => {
  const middlewares = [thunk, newSocketMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares)
  const composedEnhancers = compose(middlewareEnhancer)
  return createStore(
    rootReducer,
    composedEnhancers
  );
};
