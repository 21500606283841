import React, { useEffect, useState } from "react";
import { Switch, Route, Routes, Navigate } from "react-router-dom";
import DefaultRoute from "./default";
import GuestRoute from "./hoc/GuestRoute";
import PrivateRoute from "./hoc/PrivateRoute";
import SetPassword from "../views/pages/SetPassword/SetPassword";
import Auth from "../views/pages/Auth/Auth";
import Home from "../views/pages/Home/Home";
import { connect } from "react-redux";
import { me } from "../redux/auth/action";
import firebase from "firebase/app";
import processes from "../api/process";
import VersionCheck from "../views/components/VersionCheck/VersionCheck";
import i18n from "i18next";
import { AllRoutes } from "../views/pages/Home/AllRoutes";
import Monitoring from "../views/pages/Monitoring/Monitoring";
import Client from "../views/pages/Client/Client";
import NewClient from "../views/pages/NewClient/NewClient";
import Specialization from "../views/pages/Specialization/Specialization";
import HospitalsDivisionsList from "../views/components/HospitalsList/HospitalsDivisionsList";
import Subscription from "../views/pages/Subscription/Subscription";
import { Divisions } from "../views/pages/Divisions/Divisions";
import Feedbacks from "../views/pages/Feedbacks/Feedbacks";
import SpecializationMapping from "../views/pages/SpecializationMapping/SpecializationMapping";
import PushNotifications from "../views/pages/PushNotifications/PushNotifications";
import FavoritePatients from "../views/pages/FavoritePatients/FavoritePatients";
import Subscribers from "../views/pages/Subscribers/Subscribers";
import PromoCodes from "../views/pages/PromoCodes/PromoCodes";
import Shedule from "../views/pages/Shedule/Shedule";
import Employees from "../views/pages/Employees/Employees";
import Banners from '../views/pages/Banners/Banners';
import Onboarding from '../views/pages/Onboarding/Onboarding';
import Symptoms from '../views/pages/Symptoms/Symptoms';
import Hospitals from "../views/pages/Hospitals/Hospitals";
import { roleIs } from "../helpers/functions";

const App = ({ me, user }) => {

  const [loading, setLoading] = useState(true);

  const getMe = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      await me()
        .then(() => {
          processes.startCheckProcesses();
          console.log("Get user -> success")
        })
        .catch(() => localStorage.removeItem("token"));
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();

    const firebaseConfig = {
      apiKey: "AIzaSyB6_Dpzsy22DATLAiUwoy2HMFeXQomBRdM",
      authDomain: "dobrodoc-392f5.firebaseapp.com",
      databaseURL: "https://dobrodoc-392f5.firebaseio.com",
      projectId: "dobrodoc-392f5",
      storageBucket: "dobrodoc-392f5.appspot.com",
      messagingSenderId: "895113743631",
      appId: "1:895113743631:web:96b9d0283b5172b396c27a",
      measurementId: "G-W96KLKL1Z8",
    };

    // Initialize Firebase
    !firebase.apps.length && firebase.initializeApp(firebaseConfig);
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem("lang") || "uk";
    i18n.changeLanguage(lang)
    if (user && user.isLogged && !user.email) {
      setLoading(true);
      getMe();
    }
  }, [user]);

  const {isLogged, roles} = user || {}

  let path;
  let role = '';
  if (isLogged) {
    path = "/home";
  } else {
    path = "/login";
  }

  if (isLogged && roles) {
    if (roles && roles.includes("ROLE_ADMIN")) {
      path = "/home/admin/shedule";
      role = 'admin'
    } else if (roles && roles.includes("ROLE_INSURANCE_ADMIN")) {
      path = "/home/insurance-admin/promocodes";
      role = 'insurance-admin'
    }else if (roles && roles.includes("ROLE_DIVISION_ADMIN")) {
      path = "/home/division-admin/shedule";
      role = "division-admin"
    } else if (roles && roles.includes("ROLE_OPERATOR")) {
      role = "operator"
      path = "/home/operator/monitoring?page=1&period=All";
    } else if (roles && roles.includes("ROLE_INSURANCE_OPERATOR")) {
      role = "insurance-operator"
      path = "/home/insurance-operator/monitoring?page=1&period=All";
    } else if (roles && roles.includes("ROLE_DOCTOR")) {
      role = "doctor"
      path = "/home/doctor/monitoring?page=1&period=All";
    }
  }

  return (
    <>
      {!loading && (
        <Routes>
          <Route element={<Home role={role}/>}>
            <Route path="/" element={<Navigate to={path}/>}/>
            <Route path="/home/admin/shedule" element={<PrivateRoute><Shedule role={role}/></PrivateRoute>}/>
            <Route path="/home/division-admin/shedule" element={<PrivateRoute><Shedule role={role}/></PrivateRoute>}/>
            <Route path="/home/division-admin/employees" element={<PrivateRoute><Employees role={role}/></PrivateRoute>}/>
            <Route path="/home/admin/employees" element={<PrivateRoute><Employees role={role}/></PrivateRoute>}/>
            <Route path="/home/admin/client" element={<PrivateRoute><Client role={role}/></PrivateRoute>}/>
            <Route path={`/home/:role/user`} element={<PrivateRoute><Client role={role}/></PrivateRoute>}/>
            <Route path={`/home/:role/client/:patient?/:card?`} element={<PrivateRoute><Client role={role}/></PrivateRoute>}/>
            <Route path="/home/operator/newClient" element={<PrivateRoute><NewClient role={role} /></PrivateRoute>}/>
            <Route path="/home/insurance-operator/newClient" element={<PrivateRoute><NewClient role={role}/></PrivateRoute>}/>
            <Route path="/home/admin/specialization" element={<PrivateRoute><Specialization role={role}/></PrivateRoute>}/>
            <Route path="/home/admin/hospitals" element={<PrivateRoute><Hospitals role={role}/></PrivateRoute>}/>
            <Route path="/home/admin/hospitals/division/:id?" element={<PrivateRoute><HospitalsDivisionsList role={role}/></PrivateRoute>}/>
            <Route path="/home/admin/banners" element={<PrivateRoute><Banners role={role}/></PrivateRoute>}/>
            <Route path="/home/admin/subscription" element={<PrivateRoute><Subscription role={role}/></PrivateRoute>}/>
            <Route path="/home/admin/onboarding" element={<PrivateRoute><Onboarding role={role}/></PrivateRoute>}/>
            <Route path="/home/admin/symptoms" element={<PrivateRoute><Symptoms role={role}/></PrivateRoute>}/>
            <Route path="/home/admin/companies" element={<PrivateRoute><Divisions role={role}/></PrivateRoute>}/>
            <Route path="/home/admin/feedbacks" element={<PrivateRoute><Feedbacks role={role} /></PrivateRoute>}/>
            <Route path="/home/admin/spec-mapping" element={<PrivateRoute><SpecializationMapping role={role}/></PrivateRoute>}/>
            <Route path="/home/admin/push-notifications" element={<PrivateRoute><PushNotifications role={role} /></PrivateRoute>}/>
            <Route path="/home/operator/monitoring" element={<PrivateRoute><Monitoring role={role} /></PrivateRoute>}/>
            <Route path="/home/insurance-operator/monitoring" element={<PrivateRoute><Monitoring role={role} /></PrivateRoute>}/>
            <Route path="/home/doctor/monitoring" element={<PrivateRoute><Monitoring role={role} /></PrivateRoute>}/>
            <Route path="/home/doctor/monitoring/:patientId" element={<PrivateRoute><Monitoring role={role} /></PrivateRoute>}/>
            <Route path="/home/doctor/favorite-clients" element={<PrivateRoute><FavoritePatients role={role} /></PrivateRoute>}/>
            <Route path="/home/admin/subscription/subscribers/:id?" element={<PrivateRoute><Subscribers /></PrivateRoute>}/>
            <Route path="/home/admin/promocodes" element={<PrivateRoute><PromoCodes role={role} /></PrivateRoute>}/>
            <Route path="/home/insurance-admin/promocodes" element={<PrivateRoute><PromoCodes role={role} /></PrivateRoute>}/>
          </Route>
          <Route path="/login" element={<Auth/>} />
          <Route path="/setpassword:token?" element={<SetPassword/>} />
        </Routes>
      )}
      <VersionCheck/>
    </>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = { me };

export default connect(mapStateToProps, mapDispatchToProps)(App);
