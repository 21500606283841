import React, { useEffect, useState } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { getFormValues } from "redux-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { LocationCity } from "@material-ui/icons";
import {
  getAllConsultations,
  getFilteredConsultations,
  getConsultationsByPationId,
  setPagination,
  getConsultationsPrimary,
} from "../../../redux/consultations/actions";
import {
  clearPatient,
  getPatientCard,
  resetPatientsList,
} from "../../../redux/patients/actions";
import specEdit from "../../../style/icons/PersonEdit/person-edit.svg";

import Button from "../ui/Button/Button";
import {
  getConsultationsListsByDate,
  getPageCount,
  getIsItemsFiltered,
  getFilterItemsCount,
  getTotalItemCount,
  getTriggerCount,
  getCurrentPage,
  getLoading,
  getLoadingState,
  getFilterMode,
} from "../../../redux/consultations/selectors";

import plus from "../../../style/icons/PlusInCircle/plus-white.svg";
import docIcon from "../../../style/icons/DocIcon/doc.svg";
import call from "../../../style/icons/Call/call.svg";
import clinic from "../../../style/icons/Type/clinic.svg";
import home from "../../../style/icons/Home/home_28x28.svg";
import camera from "../../../style/icons/Camera/camera.svg";
import msg from "../../../style/icons/Message/message.svg";
import patient_active from "../../../style/icons/Subscription/patient_active.svg";
import { PaginatorPrimary } from "../../components";
import Spinner from "../ui/Spinner/Spinner";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { getFormatDate } from "../../../utils/date";
import "./consultateList.scss";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { createHistoryPush } from "../../../helpers/functions";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#fce022",
      color: "black",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: "#fce022",
      },
    },
    "& > *": {
      marginTop: theme.spacing(1),
      fontSize: "16px",
      "& ul li button": {
        fontSize: "15px",
        fontWeight: "500",
        "&*": {
          backgroundColor: "#3288cd",
        },
      },
    },
  },
}));

const actionType = {
  0: msg,
  1: call,
  2: camera,
  3: "TYPE_HOME",
  // 4: "TYPE_HOSPITAL",
  4: clinic,
};

const FILTERS_LIST = ["All", "Today", "Tomorrow", "Week"];

const ConsultateList = ({
  handleEdit,
  loading,
  searchKey,
  edited,
  setEdited,
  resetPatientsList,
  // setLoading,
  getConsultationsByPationId,
  getConsultationsPrimary,
  monitoringFilterValues,
  filterMode,
  items = [],
  role,
  page_count,
  filter,
  setFilter,
  filterItemsCount = {
    countAll: "",
    countToday: "",
    countTomorrow: "",
    countWeek: "",
  },
  filterParams,
  currentPage,
  period,
  urlParams,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const location = useLocation()
  const navigate = useNavigate()
  const patientId = searchParams.get("patientId");
  const searchQuery = searchParams.get("query")

  const handleChangePage = (_, numberOfPage) => {
    navigate(`${location.pathname}${location.search.replace(`page=${currentPage}`, `page=${numberOfPage}`)}`)
  };

  useEffect(() => {
    setPage(1);
  }, [filter]);

  useUpdateEffect(() => {
    if (edited) {
      setTimeout(() => {
        refreshConsultations();
      }, 1000);
    }
    setEdited(false);
  }, [edited]);

  const refreshConsultations = () => {
    // setLoading(true);
    // if (role === "doctor" && patientId) {
    //   getConsultationsByPationId({ patientId, filter, page });
    //   return;
    // }
    // getConsultationsPrimary({ role, filter, page });
  };

  useEffect(() => {
    if (filter && page) {
      console.log(1111);
      // refreshWithFilters();
    }
  }, [filter, page]);

  useEffect(() => {
    refreshConsultations();
  }, [patientId]);

  const changeViewPeriod = (filterMode) => {
    setFilter(filterMode);
  };

  const refreshWithFilters = () => {
    if (!window.location.pathname.includes("home")) return;

    if (role === "doctor" && patientId) {
      getConsultationsByPationId({ patientId, filter, page });
      return;
    }

    getConsultationsPrimary({
      role,
      filter,
      page,
      filterParams: {
        status: monitoringFilterValues?.status?.value,
        type: monitoringFilterValues?.serviceFormat?.type,
        inWork:
          monitoringFilterValues?.allOrInWork?.value === "inWork"
            ? moment().format("YYYY-MM-DD")
            : "",
        specialization:
          monitoringFilterValues?.specialization?.length && role === "operator"
            ? monitoringFilterValues.specialization.map((el) => el.id)
            : "",
      },
    });
  };

  const openConsultation = (item) => {
    if (item.type === 1) {
      const provinceTitle = item.address?.province?.title || "";
      const districtTitle = item.address?.district?.title || "";
      const addressString = Object.keys(item.address).map(el => {
        if (el === "province" || el === "district") {
          return `${el}=${item.address[el].title}`
        }
        return `${el}=${item.address[el] ?? ""}`
      }).join("&")

      navigate(
        `/home/${role}/client/${item.patient.id}/${item.patientCard.id}/?user=${item.patient.userId}&consultId=${item.id}&${addressString}`,
        {from: `${location.pathname}${location.search}`}
      );
    } else {
      navigate(
        `/home/${role}/client/${item.patient.id}/${item.patientCard.id}/?user=${item.patient.userId}&consultId=${item.id}`,
        {from: `${location.pathname}${location.search}`}
      );
    }
    localStorage.setItem("currentConsultationId", item.id); // need to check
  };

  const openEditor = (e, id) => {
    handleEdit(id);
    e.stopPropagation();
  };

  const handleChangeFilter = (newPeriod) => {
    createHistoryPush({
      location,
      navigate,
      ...urlParams,
      page: 1,
      period: newPeriod,
      from: "",
      to: ""
    })
  }

  return (
    <div className="consultaleListContainer">
      {loading && (
        <div className="absoluteCenter">
          <Spinner />
        </div>
      )}
      {items && !loading && (
        <>
          <div className="titleWithFilters">
            <div className="title">{t("monitoring")}</div>
            {(!patientId || role != "doctor") && (
              <div className="filters">
                {FILTERS_LIST.map((filter) => (
                  <div
                    key={filter}
                    className={`filter ${filter === period ? "active" : ""}`}
                    onClick={() => handleChangeFilter(filter)}
                  >
                    {`${t(`filter${filter}`)} ${filterItemsCount[`count${filter}`]}`}
                  </div>
                ))}
              </div>
            )}
            {searchQuery && role === "doctor" && patientId && (
              <div className="patientsSearchKeyBox">
                <div className="searchKey">{searchQuery}</div>
                <IconButton
                  onClick={() => {
                    resetPatientsList();
                    navigate(`/home/doctor/monitoring?page=1&period=All`);
                  }}
                  variant="contained"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </div>
          <div className="consultationItemsContainer">
            {items.length > 0 ? (
              items.map(([date, list]) => (
                <div className="consultationPeriod" key={date}>
                  <div className="date">{getFormatDate(date)}</div>
                  {list.map((item, index) => (
                    <div
                      key={index}
                      className={`consultationItem ${
                        item.type == 1 ? "home" : ""
                      }`}
                      onClick={() => {
                        if(!item?.patientCard?.deleted){
                          openConsultation(item);
                        }else{
                          alert(t("deletedPatientCard"))
                        }
                      }}
                    >
                      {item?.payment?.paymentNumber && (
                        <div className="paymentNumber">
                          &#8470; {item.payment.paymentNumber}
                        </div>
                      )}
                      <div className="id">
                        <div className="title">ID</div>
                        <div className="idInfo">#{item.id}</div>
                      </div>
                      <div className="patient">
                        <div className="title">{t("patient")}</div>
                        <div className="patientInfo">
                          <img
                            src={
                              (item.patientCard && item.patientCard.photoUrl) ||
                              patient_active
                            }
                            alt="patient photo"
                          />
                          <div className="details">
                            <span className={`name ${item.patientCard.deleted && 'patientDeleted'}`}>
                              {item.patientCard && item.patientCard.fullName}
                            </span>
                            {item.patientCard &&
                              item.patientCard.insuranceCompany &&
                              item.patientCard.insuranceCompany.title && (
                                <span className="insuranceCompanyName">
                                  {item.patientCard.insuranceCompany.title}
                                </span>
                              )}
                            {item.type == 1 && (
                              <div className="address">
                                <LocationCity
                                  style={{
                                    color: "#4b9bf5",
                                    marginRight: "5px",
                                    verticalAlign: "middle",
                                  }}
                                />
                                {item.address?.province?.title || ""},{" "}
                                {item.address?.district?.title || ""}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {(role === "operator" ||
                        role === "insurance-operator") && (
                        <div className="doc">
                          <div className="title">
                            {t("specializationTitle")}
                          </div>
                          <div className="docInfo">
                            <img
                              src={
                                (item.doctor &&
                                  item.doctor.employee.photoUrl) ||
                                docIcon
                              }
                              alt="doc-avatar"
                            />
                            <div className="details">
                              <span className="name">
                                {item.doctor &&
                                  item.doctor.employee &&
                                  item.doctor.employee.fullName}
                              </span>
                              <span className="spec">
                                {item.specialty && item.specialty.title}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {role === "doctor" && (
                        <>
                          <div className="cause">
                            <div className="title">{t("cause")}</div>
                            <div className="causeInfo">
                              {(item.anamnesisMonitoring &&
                                item.anamnesisMonitoring.content) ||
                                t("empty")}
                            </div>
                          </div>
                          <div className="spec">
                            <div className="title">
                              {t("placeholderSpecialty")}
                            </div>
                            <div className="specInfo">
                              {item.specialty && item.specialty.title}
                            </div>
                          </div>
                        </>
                      )}
                      <div className="status">
                        <div className="title">{t("status")}</div>
                        <div className="statusInfo">
                          <span className="time">
                            {`${getFormatDate(item.start, "HH:mm")}`}
                          </span>
                          <span className={`statusRecord ${item.status}`}>
                            {t(item.status)}
                          </span>
                        </div>
                      </div>
                      <div
                        className={
                          actionType[item.method] === "TYPE_HOME" &&
                          role === "operator"
                            ? "actionHome"
                            : "action"
                        }
                      >
                        {actionType[item.method] === "TYPE_HOME" ? (
                          <>
                            <img src={home} alt="" />
                            {role === "operator" && (
                              <div
                                onClick={(e) => openEditor(e, item.id)}
                                className="editContainer"
                              >
                                <img
                                  src={specEdit}
                                  className="editIcon"
                                  alt="startEditing"
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <img src={actionType[item.method]} alt="" />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <>
                {role === "operator" && (
                  <div className={"createClientButton"}>
                    <p>{t("emptyList")}</p>
                    <NavLink to={`/home/operator/newClient/`}>
                      <Button
                        className={"btn"}
                        withIcon
                        icon={plus}
                        type="button"
                        text={t("createButton")}
                      />
                    </NavLink>
                  </div>
                )}
                {role === "doctor" && (
                  <p className="monitoringEmptyText">{t("emptyList")}</p>
                )}
              </>
            )}
          </div>
          <div className={classes.root}>
            <Pagination
              classes={{ ul: classes.ul }}
              count={page_count}
              color="primary"
              page={+currentPage}
              defaultPage={1}
              size="large"
              onChange={handleChangePage}
            />
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    items: getConsultationsListsByDate(state),
    filterItemsCount: getFilterItemsCount(state),
    page_count: getPageCount(state),
    current_page: getCurrentPage(state),
    monitoringFilterValues: getFormValues("monitoringFilters")(state),
    isItemsFiltered: getIsItemsFiltered(state),
    trigger_count: getTriggerCount(state),
    total_item_count: getTotalItemCount(state),
    loading: getLoadingState(state),
    filterMode: getFilterMode(state),
    searchKey: state?.patients?.searchKey,
  };
};

export default connect(mapStateToProps, {
  getAllConsultations,
  getFilteredConsultations,
  getConsultationsByPationId,
  getPatientCard,
  getConsultationsPrimary,
  setPagination,
  clearPatient,
  resetPatientsList,
})(ConsultateList);
