import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  getMessageList,
  getBothInChat,
  getIsNeedScrolling,
  getItemsPerPage,
  getTotalItemCount,
  getCurrentPage,
  getIsTheEndPage,
} from "../../../../redux/chat/selectors";

import {
  getChatHistory,
  deleteChatMessage,
  clearHistoryChat,
} from "../../../../redux/chat/actions";
import { MessageComponent } from "./";
import moment from "moment";

const MESSAGE_LIMIT = 20;

const MessagesList = ({
  role,
  t,
  userId,
  setMessageValue,
  bothInChat,
  setMessageEdit,
  consultId,
  historyChat,
  status,
  videoCall,
  totalItemCount,
  itemsPerPage,
  getChatHistory,
  deleteChatMessage,
  clearHistoryChat,
  isNeedScrolling,
  updateChatHistory,
  currentPage,
  isTheEndPage,
  messageListRef,
  handleScrollBottom,
}) => {
  const prevScrollPosition = useRef(null);
  const scrollOnNextPage = useRef(false)
  const calculateScrollPosition = () => {
    const { scrollHeight, clientHeight } = messageListRef.current
    const currentPosition = currentPage === 0
      ? 0
      : scrollHeight - clientHeight
    const delta = currentPosition - prevScrollPosition.current
    const scrollToPosition = delta < 0 ? prevScrollPosition.current : delta
    return scrollToPosition
  }

  const scrollHandler = (e) => {
    if (totalItemCount <= currentPage * itemsPerPage) {
      return;
    }
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    const isTheEnd = scrollTop === 0;
    if (isTheEnd && !isTheEndPage) {
      prevScrollPosition.current = scrollHeight - clientHeight;
      scrollOnNextPage.current = true
      updateChatHistory(currentPage + 1);
    }
  };

  useEffect(() => {
    prevScrollPosition.current = null
    updateChatHistory();
    return () => clearHistoryChat();
  }, [userId]);

  useEffect(() => {
    if (currentPage === 0) {
      handleScrollBottom()
      return
    }
    if (!scrollOnNextPage.current) {
      handleScrollBottom()
    }
    if(currentPage > 0 && scrollOnNextPage.current) {
      handleScrollBottom(calculateScrollPosition())
      scrollOnNextPage.current = false
    }
  }, [currentPage, historyChat])

  return (
    <div
      ref={messageListRef}
      className={`${videoCall ? "messagesArea video" : "messagesArea"} ${status}`}
      id="messagesArea"
      onScroll={scrollHandler}
    >
      {historyChat.map((group, i) => (
        <div className="one_day_messages" key={`${group.date}${i}`}>
          <div className="chatDate">{moment(group.date, "D.M.YYYY").format("DD.MM.YYYY")}</div>
          {group.list.map((item, i) => (
            <MessageComponent
              key={i}
              item={item}
              bothInChat={bothInChat}
              t={t}
              role={role}
              deleteChatMessage={deleteChatMessage}
              setMessageValue={setMessageValue}
              chooseMessageId={setMessageEdit}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  historyChat: getMessageList(state),
  totalItemCount: getTotalItemCount(state),
  itemsPerPage: getItemsPerPage(state),
  bothInChat: getBothInChat(state),
  isNeedScrolling: getIsNeedScrolling(state),
  currentPage: getCurrentPage(state),
  isTheEndPage: getIsTheEndPage(state),
});

export default connect(mapStateToProps, {
  getChatHistory,
  deleteChatMessage,
  clearHistoryChat,
})(MessagesList)
