import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { getDepartmentsByHospitalId } from "../../../redux/hospitals/actions";
import arrowLeft from "../../../style/icons/ArrowLeftLight/arrow-left.svg";

import NewHospitalAddressForm from "../../forms/NewHospitalAddressForm/NewHospitalAddressForm";
import Spinner from "../ui/Spinner/Spinner";
import Splitter from "../ui/Splitter/Splitter";

import "./HospitalsDivisionsList.scss";

const HospitalsDivisionsList = ({
  getDepartmentsByHospitalId,
  hospitalsDivisions = [],
}) => {
  const params = useParams();
  const { t } = useTranslation();

  const [editItem, setEditItem] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDepartmentsByHospitalId(params.id).then(() => setLoading(false));
  }, []);

  const HospitalsDivisionsItemsList = () => {
    const navigate = useNavigate();
    return (
      <>
        {loading ? (
          <Spinner />
        ) : (
          <div className="hospitalsDivisionsListWrapper">
            <div className="pageHeader">
              <img
                className="arrowLeft"
                alt="arrow-back"
                src={arrowLeft}
                onClick={() => navigate("/home/admin/hospitals")}
              />
                <p className="pageTitle">{t("hospitals")}</p>
            </div>
            <div className="hospitalsDivisionsItemsContainer">
              {hospitalsDivisions && hospitalsDivisions.length ? (
                hospitalsDivisions.map((division) => (
                  <div
                    className="hospitalsDivisionsItem"
                    key={division.id}
                    onClick={() =>
                      setEditItem({
                        hospitalDivisionAddress: division.addressText,
                        hospitalDivisionID: division.id,
                      })
                    }
                  >
                    <p>{division.title}</p>
                    <p>{division.addressText}</p>
                  </div>
                ))
              ) : (
                <p className="monitoringEmptyText">{t("emptyList")}</p>
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <Splitter
      left={<HospitalsDivisionsItemsList />}
      right={
        <NewHospitalAddressForm
          loading={loading}
          editItem={editItem}
          setEditItem={setEditItem}
          setLoading={setLoading}
          getDepartmentsByHospitalId={getDepartmentsByHospitalId}
          params={params}
        />
      }
    />
  );
};

const mapStateToProps = ({ hospitals }) => ({
  hospitalsDivisions: hospitals && hospitals.defaultHospitals && hospitals.defaultHospitals.items,
});

export default connect(mapStateToProps, { getDepartmentsByHospitalId })(
  HospitalsDivisionsList
);
