import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {  useNavigate } from "react-router-dom";
import specEdit from "../../../style/icons/PersonEdit/person-edit.svg";
import { setSubs, setPagination } from "../../../redux/subscriptions/actions";
import deleteButton from '../../../style/icons/audioMessage/delete.svg';
import getInfo from '../../../style/icons/Subscription/information-button.svg'
import Spinner from "../ui/Spinner/Spinner";
import Paginator from "../Paginator/Paginator";
import "./SubscriptionList.scss";
import { deleteCompany } from "../../../redux/subscriptions/actions";
import { setPopUpSuccess } from "../../../redux/networkError/actions";
import SubscriptionBanner from '../SubscriptionBanner/SubscriptionBanner'
import { setInitalValues } from "../../../redux/formInitialValues/actions";

const SubscriptionList = ({
  editId,
  handleEdit,
  deleteCompany,
  edit,
  setSubs,
  subscriptions,
  current_page,
  page_count,
  setEditBannerOpen,
  setPagination,
  setPopUpSuccess,
  handleEditBannerInfo,
  editBannerOpen,
  bannerLoading,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [bannerInfo, setBannerInfo] = useState(null)
  const infoRef = useRef(null)

  useEffect(() => {
    setLoading(true);
    setSubs().then(() => setLoading(false));
  }, []);

  const handleBannerInfo = (e, item) => {
    e.stopPropagation()
    setBannerInfo(item)
  }

  useEffect(() => {
    if (!bannerLoading && infoRef.current && bannerInfo) {
      setTimeout(() => {
        const updatedInfo = subscriptions && subscriptions.companies.items.find((el) => el.id === bannerInfo.id)
        setBannerInfo(updatedInfo)
      }, 400)
    }
  }, [bannerLoading, subscriptions])

  const redirectToSubscribers = (id) => {
    navigate(`/home/admin/subscription/subscribers/${id}`);
  };
  const handleItemClick = (item) => {
      redirectToSubscribers(item.id);
  }

  const handleEditItem = (e, item) => {
    setBannerInfo(null)
    handleEdit(item)
    e.stopPropagation()
  } 

  const handleDeleteItem = async (e, item) => {
    e.stopPropagation()
    const answer = await window.confirm("Вы действительно хотите удалить Компанию")
    if (answer) {
      deleteCompany(item.id)
        .then(() => {
          setPopUpSuccess({
            response: {
              succes: true,
              status: t("success"),
              data: {
                message: t("deleteSuccess"),
              }
            }
          })
        })
    }
  }
  
  return (
    <div className="dashBoardContainerUnique">
      {loading ? (
        <Spinner />
      ) : subscriptions &&
        subscriptions.subscriptions.items.length > 0 &&
        subscriptions.companies.items.length > 0 ? (
        <>
          {/*{subscriptions && subscriptions.subscriptions.items.length > 0 && (*/}
          {/*  <>*/}
          {/*    <div className="title">{t("subscriptions")}</div>*/}
          {/*    <div className="subscription-wrapper">*/}
          {/*      {subscriptions.subscriptions.items.map((item, i) => {*/}
          {/*        return (*/}
          {/*          <div key={i} className="subscription-item">*/}
          {/*            <p className="subscription-header">{t("rates")}</p>*/}
          {/*            <p className="subscription-title">{item.title}</p>*/}
          {/*            <p className="subscription-amount">*/}
          {/*              {item.usersCount}*/}
          {/*              <span className="subscription-patient">*/}
          {/*                {t("patients")}*/}
          {/*              </span>*/}
          {/*            </p>*/}
          {/*          </div>*/}
          {/*        );*/}
          {/*      })}*/}
          {/*    </div>*/}
          {/*  </>*/}
          {/*)}*/}
          {subscriptions && subscriptions.companies.items.length > 0 && (
                <>
                  {bannerInfo && (
                    <SubscriptionBanner
                      handleEditBannerInfo={handleEditBannerInfo}
                      setInitalValues={setInitalValues}
                      setEditBannerOpen={setEditBannerOpen}
                      setBannerInfo={setBannerInfo}
                      bannerInfo={bannerInfo}
                      bannerLoading={bannerLoading}
                      t={t}
                    />
                  )}
              <div className="title">{t("companies")}</div>
                  <div className={`subscription-wrapper ${bannerInfo ? 'showBanner' : ''}`}>
                {subscriptions.companies.items.map((item, index) => {
                  return (
                    <div
                      onClick={() => handleItemClick(item)}
                      key={index}
                      className={`subscription-item ${editBannerOpen ? 'editMode' : ""} ${item.id === editId
                        ? 'editedItem'
                        : (edit && item.id !== editId ? 'editMode' : '')}`
                      }
                    >
                      <p className="subscription-header">{t("rates")}</p>
                      <p className="subscription-title">
                        {item.title}
                      </p>
                      <p className="subscription-amount">
                        {item.usersCount}
                        <span className="subscription-patient">
                          {t("patients")}
                        </span>
                      </p>
                      {/* HIDE TO SHOW IN RELEASE */}
                      <div className='optionContainer'>
                        <span className='deleteOption'>
                          <img
                            ref={infoRef}
                            onClick={(e) => handleBannerInfo(e, item)}
                            width='25'
                            src={getInfo}
                            alt="infoButton"
                          />
                        </span>
                        <div style={{display: "flex"}}>
                          <span className='deleteOption'>
                          <img
                            onClick={(e) => handleDeleteItem(e, item)}
                            width='25'
                            src={deleteButton}
                            alt="deleteButton"
                          />
                        </span>
                        <span className='editOption'>
                          <img
                            onClick={(e) => handleEditItem(e, item)}
                            width='25'
                            src={specEdit}
                            alt="editIcon"
                          />
                        </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {subscriptions.companies.items.length > 0 && page_count !== 0 && (
                <Paginator
                  current_page={current_page}
                  setPagination={setPagination}
                  setLoading={setLoading}
                  page_count={page_count}
                  intervalDisabled
                />
              )}
            </>
          )}
        </>
      ) : (
        <p className="empty-list">{t("emptyList")}</p>
      )}
    </div>
  );
};
const mapStateToProps = ({ subscription, user }) => ({
  subscriptions:
    subscription &&
    subscription.subscriptions &&
    subscription.subscriptions.companies &&
    subscription.subscriptions,
  current_page:
    subscription &&
    subscription.subscriptions &&
    subscription.subscriptions.companies &&
    subscription.subscriptions.companies.current_page,
  page_count:
    subscription &&
    subscription.subscriptions &&
    subscription.subscriptions.companies &&
    subscription.subscriptions.companies.page_count,
  token: user && user.token,
});

export default connect(mapStateToProps, {
  setInitalValues,
  setSubs,
  setPagination,
  deleteCompany,
  setPopUpSuccess,
})(
  SubscriptionList
);
