import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {  useLocation, useNavigate, useSearchParams,  } from "react-router-dom";
import { compose } from "redux";
import { createHistoryPush } from "../../../helpers/functions";
import useUpdateEffect from "../../../hooks/useUpdateEffect";

import { getAllFavoritePatients } from "../../../redux/patients/actions";
import { getFavouritePatientsCount, getFavouritePatientsList, getFavouritePatientsPage, getFavouritePatientsPageCount } from "../../../redux/patients/selectors";

import FavoritePatientsList from "../../components/FavoritePatientsList/FavoritePatientsList";
import Splitter from "../../components/ui/Splitter/Splitter";

import "./FavoritePatients.scss";

const FavoritePatients = ({
  listItems = [],
  getAllFavoritePatients,
  role = "",
  currentPage = Number,
  pageCount = Number,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation();
  const page = searchParams.get("page")
  
  console.log("currentPage", currentPage);
  const refreshPage = () => {
    if (listItems.length && page === 1) return
    setLoading(true);
    getAllFavoritePatients(page).then(() => setLoading(false));
  }

  useEffect(refreshPage, []);
  useUpdateEffect(refreshPage, [page])

  const handleChangePage = (_, newPage) => {
    createHistoryPush({
      location,
      navigate,
      page: newPage,
    })
  };

  const handleOpenChat = (item) => {
    return () => {
      localStorage.setItem("isPersonalDoctor", "true");
      navigate(
        `/home/${role}/client/${item.patientId}/${item.id}?user=${item.patientId}&consultId=&isPersonalDoctor=true`,
        { from: `${location.pathname}${location.search}` }
      );
    }
  }

  return (
    <FavoritePatientsList
      handleOpenChat={handleOpenChat}
      handleChangePage={handleChangePage}
      listItems={listItems}
      loading={loading}
      role={role}
      currentPage={page}
      pageCount={pageCount}
      t={t}
    />
    // <Splitter
    //   left={
    //     <FavoritePatientsList
    //       handleOpenChat={handleOpenChat}
    //       handleChangePage={handleChangePage}
    //       listItems={listItems}
    //       loading={loading}
    //       role={role}
    //       currentPage={page}
    //       pageCount={pageCount}
    //       t={t}
    //     />
    //   }
    //   right={null}
    // />
  );
};

const mapStateToProps = (state) => ({
  listItems: getFavouritePatientsList(state),
  currentPage: getFavouritePatientsPage(state),
  pageCount: getFavouritePatientsPageCount(state),
  itemsCount: getFavouritePatientsCount(state),
});

const enhance = compose(
  
  connect(mapStateToProps, {getAllFavoritePatients})
)

export default enhance(FavoritePatients)

// export default connect(mapStateToProps, { getAllFavoritePatients, setPagination })(
//   FavoritePatients
// );
