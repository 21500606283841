import React from "react";
import { connect } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { roleIs } from "../../helpers/functions";
import Home from "../../views/pages/Home/Home";

const PrivateRoute = ({ user, children }) => {
  const location = useLocation()
  const pathname = location.pathname.split("/");

  const { isLogged, roles } = user || {};

  let path;
  if (isLogged) {
    path = "/home";
  } else {
    path = "/login";
  }

  if (isLogged && roles) {
    if (roles && roles.includes("ROLE_ADMIN")) {
      path = "/home/admin/shedule";
    } else if (roles && roles.includes("ROLE_INSURANCE_ADMIN")) {
      path = "/home/insurance-admin/promocodes";
    }else if (roles && roles.includes("ROLE_DIVISION_ADMIN")) {
      path = "/home/division-admin/shedule";
    } else if (roles && roles.includes("ROLE_OPERATOR")) {
      console.log('OPERATOR');
      path = "/home/operator/monitoring?page=1&period=All";
    } else if (roles && roles.includes("ROLE_INSURANCE_OPERATOR")) {
      path = "/home/insurance-operator/monitoring?page=1&period=All";
    } else if (roles && roles.includes("ROLE_DOCTOR")) {
      path = "/home/doctor/monitoring?page=1&period=All";
    }
  }

  const isLoggedIn = user &&
    user.isLogged &&
    user.roles &&
    user.roles.some((el) => roleIs(el));
    

  return isLoggedIn ? children : <Navigate to="/login" />;
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(PrivateRoute);
