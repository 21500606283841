import React from "react";
import { connect } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import defaultUserIcon from "../../../style/icons/Subscription/patient_active.svg";
import "./PatientsSearchList.scss";
import { resetPatientsList } from "../../../redux/patients/actions";

const PatientsSearchList = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goToConsultation = (item) => {
    switch(item.type) {
      case "patientCard":
        if (props.role === "doctor") {
          navigate(`/home/doctor/monitoring?page=1&period=All&patientId=${item.patientId}&query=${item.fullName}`);
          return;
        }

        navigate(
          `/home/${props.role}/client/${item.patientId}/${item.patientId ? `${item.patientCardId}/` : ""}?user=${item.userId}&consultId=`
        );
        break;

      case "user":
        navigate(
          `/home/${props.role}/user?user=${item.userId}&consultId=`
        );
        break;

      default:
        navigate(-1);
    }
  };

  const selectPatientHandler = (item) => {
    if ("patientCard" === item.type || "user" === item.type) {
      goToConsultation(item)
      if (props.role === 'operator') props.resetPatientsList()
      return
    }
  };

  const getSearchResultTypePlaceholder = (type) => {
    switch(type) {
      case 'patientCard':
        return 'placeholderMedCard';
      case 'user':
        return 'placeholderUser';
      default:
        return 'empty';
    }
  };

  if (props.searchKey && Array.isArray(props.list) && props.list.length) {
    return (
      <div className="patients-search-list with-scrollbar">
        {props.list.map((item, index) => {
          return (
            <div
              onClick={() => selectPatientHandler(item)}
              key={index}
              className="patients-search-item"
            >
              <img
                src={item.photoUrl ? item.photoUrl : defaultUserIcon}
                alt=""
                className="photo"
              />
              <span className="name">{item.fullName}</span>
              {item.phone && item.type === 'patientCard' && (
                <>
                  <span className="phone gray">{t("phone")}</span>
                  <span className="phone">{item.phone}</span>
                </>
              )}
              {item.phone && item.type === 'user' && (
                <>
                  <span className="phone gray">{t("placeholderLogin")}</span>
                  <span className="phone">{item.phone}</span>
                </>
              )}
              {typeof item.type === "undefined" || (
                <span className="type">{t(getSearchResultTypePlaceholder(item.type))}</span>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  if (props.searchKey && Array.isArray(props.list)) {
    return <div className="patients-search-list">{Array.isArray(props)}</div>;
  }

  return <></>;
};

const mapStateToProps = (state) => ({
  list: state.patients && state.patients.searchList,
  searchKey: state.patients && state.patients.searchKey,
});

const mapDispatchToProps = {
  resetPatientsList
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientsSearchList);
