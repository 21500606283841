import React from "react";
import { useTranslation } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import Toggle from "../formComponent/Toggle/Toggle";
import Button from "../ui/Button/Button";
import Spinner from "../ui/Spinner/Spinner";
import FilterSelect from "../formComponent/FilterSelect/FilterSelect";
import DatePicker from "../formComponent/DatePicker/DatePicker";
import Input from "../formComponent/Input/Input";

import calendar from "../../../style/icons/Calendar/calendar.svg";
import close from "../../../style/icons/Close/close-icon.svg";

import plus from "../../../style/icons/PlusInCircle/plus.svg";

import { generatePromocodes, getPromocodesWithParams } from "../../../redux/promocodes/actions";

import "./filter.scss";
import { validDateEnd, validDateStart } from "../../../utils/date";
import { changeServerDateFormat, getServerMoment, getServerTimeFormat } from "../../../helpers/dateFormats";
import { createHistoryPush, findInArray} from "../../../helpers/functions";
import {promocodeTypes, promocodeStatuses, togglePromocodeOptions} from "./formats"
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { requiredInteger, requiredIsNotEmpty } from "../../../helpers/validate";
import { useLocation, useNavigate } from "react-router-dom";

const filterFormFields = {
  status: "",
  promocodeToggle: "",
  type: "",
  insuranceCompany: "",
  activatedFrom: "",
  activatedTo: "",
  count: "",
  discount: "",
  specialization: "",
}

const PromocodeFilter = ({
  urlParams,
  change,
  specialtyOptions,
  insuranceOptions = [],
  formValues = {},
  buttonLoading,
  reset,
  autofill,
  generatePromocodes,
  getPromocodesWithParams,
  invalid,
}) => {
  const [formLoading, setFormLoading] = React.useState(false)
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ADD_PROMOCODE = "addPromocode"
  const FILTER_PROMOCODE = "Filter"
  const FILTER_MODE = formValues?.promocodeToggle?.value === FILTER_PROMOCODE
  const ADD_MODE = formValues?.promocodeToggle?.value === ADD_PROMOCODE
  const withSpecialty = formValues?.type?.value === "1"

  React.useEffect(() => {
    autofill("promocodeToggle", togglePromocodeOptions[0])
    return () => reset()
  }, [])

  const autoFillForm = ({reset}) => {
    if (!insuranceOptions.length) return
    const formValues = {
      status: findInArray(promocodeStatuses, urlParams.status, "value"),
      type: findInArray(promocodeTypes, urlParams.type, "value"),
      insuranceCompany: findInArray(insuranceOptions, urlParams.insuranceCompany, "id"),
      activatedFrom: changeServerDateFormat(urlParams.activatedFrom),
      activatedTo: changeServerDateFormat(urlParams.activatedTo),
    }
    Object.keys(formValues).forEach((field) => {
      autofill(field, !reset ? formValues[field] : null)
    })
  }

  React.useEffect(() => autoFillForm({reset: false}), [insuranceOptions])

  useUpdateEffect(() => {
    if (ADD_MODE) autoFillForm({reset: true})
    if (FILTER_MODE) autoFillForm({reset: false})
  }, [formValues.promocodeToggle])

  const formSubmit = (event) => {
    event.preventDefault();
    const {
      status,
      type,
      insuranceCompany,
      activatedFrom,
      activatedTo,
      promocodeToggle,
      count,
      discount,
      specialization,
      usesTotal,
    } = formValues

    if (ADD_MODE) {
      setFormLoading(true)
      const [from, to] = getServerTimeFormat([activatedFrom, activatedTo]);
      const specialtyMap = specialization && specialization.map(el => el.id) || []
      const pickedAllSpecialties = specialization?.length === specialtyOptions.length
      const specialtyScope = (type.value === "0"|| pickedAllSpecialties) ? 0 : 1
      const data = {
        count: count,
        promocode: {
          discount: +discount,
          validFrom: from || getServerMoment({add: null}),
          validTill: to || getServerMoment({from: from, add: "year" }),
          specialty: specialtyMap,
          specialtyScope,
          type: +type.value,
          insuranceCompany: insuranceCompany.id,
          usesTotal: (+discount < 100 && Number(usesTotal)) ? Number(usesTotal) : 1
        }
      }
      generatePromocodes(data)
        .finally(() => {
          setFormLoading(false)
          const noFiltersChoosen = Object.values(urlParams).filter(el => el).length === 1
          if (noFiltersChoosen) {
            getPromocodesWithParams({}, 1)
          }
          handleResetForm()
        })
    }
    if (FILTER_MODE) {
      const [from, to] = getServerTimeFormat([activatedFrom, activatedTo]);
      createHistoryPush({
        location,
        navigate,
        page: 1,
        status: status?.value,
        type: type?.value,
        insuranceCompany: insuranceCompany?.id,
        activatedFrom: from,
        activatedTo: to,
      })
    }
  };
  
  const handleResetForm = () => {
    createHistoryPush({
      location,
      navigate,
      page: 1
    })
    reset()
  };

  const handleClearDate = () => {
    change("activatedFrom", null)
    change("activatedTo", null)
    if(ADD_MODE) return
    const {search} = location
    const isDate = search.includes('activatedFrom') || search.includes('activatedTo');
    if (isDate) {
      createHistoryPush({
        location,
        navigate,
        ...urlParams,
        page: 1,
        activatedFrom: null,
        activatedTo: null,
      })
    }
  }

  
  const isFieldRequired = FILTER_MODE ? false : true

  return (
    <div className="wrappFilterForm">
      <div className="formTitle">
        {ADD_MODE ? t("addPromoCodes") : t("filterFormTitle")}
        <div onClick={handleResetForm} className="resetButtonBox">
          <div className="resetText">{t("reset")}</div>
          <img
            style={{ margin: "0px" }}
            className="resetForm"
            title="Очистить форму"
            src={close}
            alt="closeIcon"
          />
        </div>
      </div>
      <Form className="monitoringFilters" onSubmit={formSubmit}>
        <div className="formContent">
          <Field
            name="promocodeToggle"
            component={Toggle}
            options={togglePromocodeOptions}
          />
          <div className="dateRange">
            <Field
              name="activatedFrom"
              maxDate={2030}
              component={DatePicker}
              isValidDate={formValues?.promocodeToggle?.value == 'addPromocode' ? validDateStart : () => true}
              placeholder="--/--/----"
            />
            <Field
              name="activatedTo"
              maxDate={2030}
              isValidDate={formValues?.promocodeToggle?.value == 'addPromocode' ? validDateEnd : () => true}
              component={DatePicker}
              placeholder="--/--/----"
            />
            <img
              src={close}
              alt="close"
              onClick={handleClearDate}
            />
            <img
              src={calendar}
              alt="calendar"
            />
          </div>
          <Field
            name="insuranceCompany"
            component={FilterSelect}
            options={insuranceOptions}
            disableReaction
            placeholder={t("insuranceCompany")}
            validate={isFieldRequired ? [requiredIsNotEmpty] : () => {}}
            withPreview
          />
          <Field
            name="type"
            disableReaction
            component={FilterSelect}
            options={promocodeTypes}
            placeholder={t("promocodeType")}
            withPreview
            validate={isFieldRequired ? [requiredIsNotEmpty] : () => {}}
          />
          {FILTER_MODE && (
            <Field
              name="status"
              disableReaction
              component={FilterSelect}
              options={promocodeStatuses}
              placeholder={t("promocodeStatus")}
              withPreview
            />
          )}
          {ADD_MODE && (<>
            {withSpecialty && (
              <Field
                name="specialization"
                component={FilterSelect}
                placeholder={t("placeholderSpecialty")}
                options={specialtyOptions}
                withIcon
                multipleInTitle={true}
                withPreview={formValues && formValues.specialization}
                icon={plus}
                validate={[requiredIsNotEmpty]}
              />
            )}
            <Field
              name="discount"
              component={Input}
              freeTopSpace
              type="number"
              min={0}
              max={100}
              placeholder={t("percentileDiscount")}
              validate={[requiredIsNotEmpty]}
            />
            <Field
              name="count"
              component={Input}
              freeTopSpace
              type="number"
              min={0}
              max={100}
              placeholder={t("promocodeCount")}
              validate={[requiredIsNotEmpty]}
            />
            {formValues && formValues.discount !== "100" && (
              <Field
                name="usesTotal"
                component={Input}
                freeTopSpace
                type="number"
                min={0}
                max={9999999}
                placeholder={t("usesTotal")}
                validate={[requiredInteger]}
              />
            )}
          </>)}
        </div>
        <div className="formFooter">
          <Button
            className="btn"
            disabled={invalid}
            type="submit"
            loading={buttonLoading || formLoading}
            text={t("acceptButton")}
          />
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("promocodeFilter")(state),
});

const mapDispatchToProps = {
  generatePromocodes,
  getPromocodesWithParams,
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "promocodeFilter", enableReinitialize: true })
);

export default enhance(PromocodeFilter);