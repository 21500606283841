import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  
  useLocation,
  useNavigate,
  useParams,
  useRouteMatch,
  useSearchParams,
  
} from "react-router-dom";
import { compose } from "redux";
import { v4 } from "uuid";
import api from "../../../api";
import useUpdateEffect from "../../../hooks/useUpdateEffect";

import { getAllChatMessagesTemplates } from "../../../redux/chat/actions";
import { getCurrentConsultation } from "../../../redux/consultations/actions";
import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";
import { setNetworkError } from "../../../redux/networkError/actions";
import {
  getUser,
  getAllPatients,
  getPatientCard,
  getPatientCards,
  getThePatient,
  getThePatientAnamnesis,
  getThePatientAppointments,
  setPatientAsFavourite,
} from "../../../redux/patients/actions";
import { addNotification } from "../../../redux/popups/actions";

import arrowLeft from "../../../style/icons/ArrowLeftLight/arrow-left.svg";
import plus from "../../../style/icons/PlusInCircle/plus.svg";
import shevronDown from "../../../style/icons/ShevronDown/shevron-down.svg";

import Chat from "../../components/Chat/Chat";
import ConsultationCard from "../../components/ConsultattionCard/ConsultationCardNew";
import DetailAddressModal from "../../components/DetailAddressModal/DetailAddressModal";
import ShowMedCard from "../../components/MedCard/ShowMedCard";
import NewConsultation from "../../components/NewConsultation/NewConsultation";
import RelativesList from "../../components/RelativesList/RelativesList";
import Button from "../../components/ui/Button/Button";
import Spinner from "../../components/ui/Spinner/Spinner";
import ChangeLoginModal from "../../components/ChangeLoginModal";
import NewCardForm from "../../forms/NewCardForm/NewCardForm";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import "./Client.scss";
import { getCurrentConsultSelector } from "../../../redux/consultations/selectors";

const Client = ({
  role,
  patient,
  patientCard,
  getThePatientAppointments,
  user,
  chat,
  getAllChatMessagesTemplates,
  getUser,
  getThePatient,
  getPatientCards,
  getThePatientAnamnesis,
  cards,
  resetInitialValues,
  getCurrentConsultation,
  setNetworkError,
  attendingDoctor,
  addNotification,
  currentConsultation,
  setPatientAsFavourite,
}) => {
  const params = useParams();
  const { t } = useTranslation();
  const location = useLocation()
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()
  const [newCard, setNewCard] = useState(false);
  const [showRelatives, setShowRelatives] = useState(false);
  const [localPatientRelationShip, setLocalPatientRelationship] = useState(null);
  const [loading, setLoading] = useState();
  const [favouriteLoading, setFavouriteLoading] = useState(false)
  const [details, setDetails] = useState(false);
  const [historyItem, setHistoryItem] = useState(undefined);
  const [showAddress, setShowAddress] = useState(false)
  const [userPhone, setUserPhone] = useState(null);

  const patientConsultAddress = {
    province: new URLSearchParams(location.search).get("province"),
    district: new URLSearchParams(location.search).get("district"),
    street: new URLSearchParams(location.search).get("thoroughfare"),
    building: new URLSearchParams(location.search).get("houseNumber"),
    apartment: new URLSearchParams(location.search).get("flatNumber"),
    floor: new URLSearchParams(location.search).get("floor"),
    entrance: new URLSearchParams(location.search).get("entrance"),
    codeOrNote: new URLSearchParams(location.search).get("comment"),
  }

  const consultId = searchParams.get("consultId");
  const isPersonalDoctor = searchParams.get("isPersonalDoctor");

  useEffect(() => {
    setHistoryItem(undefined);
  }, [params]);

  const addCard = () => {
    setNewCard(true);
  };

  const removeCard = () => {
    setNewCard(false);
  };

  const showRel = () => {
    setShowRelatives(true);
  };

  const replaceAva = (person) => {
    if (person && person.patronymic && person.firstName)
      return (
        person.patronymic[0].toUpperCase() + person.firstName[0].toUpperCase()
      );
  };

  const toUpperCase = (person) => {
    return (
      person.relationShip &&
      person.relationShip.title[0].toUpperCase() +
        person.relationShip.title.slice(1)
    );
  };

  // const setPatientAsFavorite = (patientCardID) => {
  //   setLoading(true);

  //   api.patients
  //     .setPatientAsFavorite(patientCardID)
  //     .then(() => getCurrentConsultation(consultId))
  //     .then(() => setLoading(false));
  // };

  const addNewCard = newCard && (
    <NewCardForm toClose={removeCard} setNewCard={setNewCard} />
  );

  const medicalcard = !newCard && (
    <ShowMedCard
      historyItem={historyItem}
      propsRole={role}
      setHistoryItem={setHistoryItem}
      setDetails={setDetails}
      details={details}
    />
  );

  useUpdateEffect(() => {
    if (patient?.relationShip) {
      setLocalPatientRelationship(patient.relationShip);
    } else {
      setLocalPatientRelationship(null)
    }
  }, [patient]);

  const handleShowAddress = (e) => {
    setShowAddress(prev => !prev)
    if (!showAddress) {
      setShowAddress(true)
    }
  }

  useEffect(() => {
    console.log("MATCH:", params);
    params.patient && getPatientCards(params.patient).then((res) => {
      if (
        res.cards &&
        res.cards.items &&
        res.cards.items.length &&
        res.cards.items.length > 0
      ) {
        getThePatient(params.patient, params.card).then(
          ({ patient }) => setLocalPatientRelationship(patient.entity.relationShip)
        );
        // TODO switch by role
        getThePatientAnamnesis(params.patient, params.card);
        getThePatientAppointments(params.card);
        // getPatientCards(params.patient);
        consultId &&
          getCurrentConsultation(consultId).then(({ currentConsultation }) => {
            // console.log(
            //   "-------------------------------> Внимание",
            //   currentConsultation
            // );
            if (currentConsultation && currentConsultation.isNeedToPay) {
              // console.log("-------------------------------> Внимание");
              setNetworkError({
                response: {
                  status: t("warning"),
                  statusText: t("consultationIsNotPayed"),
                  hold: true,
                },
              });
            }
          });
      }
    });
    // }
  }, [params]);

  useEffect(() => {
    getUser(userId).then((res) => {
      console.log('user', res.user);
      setUserPhone(res.user.phone);
    });
    console.log("getAllChatMessagesTemplates");
    getAllChatMessagesTemplates();
  }, []);

  useEffect(() => {
    return function () {
      resetInitialValues();
    };
  }, []);

  useEffect(() => {
    return () => {
      localStorage.removeItem("currentConsultationId");
      localStorage.removeItem("isPersonalDoctor");
    };
  }, []);

  const handleGoBack = () => {
    if (location?.state?.from) {
      navigate(location.state.from)
    } else {
      navigate(`/home/${role}/monitoring?page=1&period=All`)
    }
  }
  
  const handleSetFavourite = (id) => {
    return () => {
      setFavouriteLoading(true)
      setPatientAsFavourite(id)
        .finally(() => setFavouriteLoading(false))
    }
  }

  const isActualCardData = patientCard?.id === Number(params.card)
  const showFavouriteButton = role === "doctor" && patient && isActualCardData
  const isFavouritePatient = patient?.attendingDoctorAdded
  const favouriteButtonClassName = `btn favoritePatientBtn ${isFavouritePatient ? "isFavoriteTrue" : "isFavoriteFalse"}`
  const favouriteButtonText = patient?.attendingDoctorAdded ? t("isFavourite") : t("addToFavourite")

  const userId = new URLSearchParams(document.location.search).get("user")

  return (
    <div className="doctorWrapper">
      {loading ? (
        <div className="locker">
          <div className="absoluteCenter">
            <Spinner />
          </div>
        </div>
      ) : (
        <>
          <div className="header">
            <div className="clientInfo">
              <div
                className="previousBox"
                onClick={handleGoBack}
              >
                <img className="arrow-left" alt="back arrow" src={arrowLeft} />
              </div>
              {isActualCardData && patient && patient.photoUrl && (
                <img
                  className="doctorImage"
                  src={patient && patient.photoUrl}
                  alt="userImg"
                />
              )}
              {isActualCardData && patient && !patient.photoUrl && (
                <div className={"patientImage"}>{replaceAva(patient)}</div>
              )}
              {!loading && isActualCardData && (
                <div className="doctorDetails">
                  <span className="doctorName">
                    {patient && patient.fullName}
                  </span>
                  {localPatientRelationShip && (
                    <span className="doctorMe">
                      {localPatientRelationShip.title}
                    </span>
                  )}
                  <br />
                  <span className="doctorPhone">{`${t("phone")} ${
                    patient && patient.phone
                      ? patient && patient.phone
                      : t("empty")
                  }`}</span>
                </div>
              )}
                {isActualCardData && role !== "doctor" && (
                  <img
                    onClick={showRel}
                    className="arrow-down"
                    alt=""
                    src={shevronDown}
                  />
                )}
                {!patient && (
                  <div className="showRelativesWrapper" style={{ position: "relative", marginLeft: "16px" }}>
                    <ChangeLoginModal userPhone={userPhone} userId={userId} />
                  </div>
                )}
                {/* {currentConsultation && (
                  <div className={`consultStatusRecord ${currentConsultation.status}`}>
                    {t(currentConsultation.status)}
                  </div>
                )} */}
                {patientConsultAddress.province && (
                  <div className="patientAddress">
                    <div onClick={handleShowAddress} className="addressText">
                      {t("address")}
                      {showAddress && <KeyboardArrowUpIcon style={{marginLeft: "5px", color: "#4b9bf5"}}/>}
                      {!showAddress && <KeyboardArrowDownIcon style={{marginLeft: "5px", color: "#4b9bf5" }} />}
                    </div>
                    {showAddress && (
                      <DetailAddressModal
                        handleClose={() => setShowAddress(false)}
                        options={patientConsultAddress}
                      />
                    )}
                  </div>
                )}
              {patient &&
                patient.patient &&
                patient.patient.isCompanySubscribed && (
                  <div className="subscriber">
                    <div className="blueCircle">
                      <div className="checkMark" />
                    </div>
                  </div>
                  )}
            </div>
            {showRelatives && (
              <RelativesList
                userPhone={userPhone}
                setHistoryItem={setHistoryItem}
                historyItem={historyItem}
                setShowRelatives={setShowRelatives}
                replaceAva={replaceAva}
                toUpperCase={toUpperCase}
                setDetails={setDetails}
              />
              )}
            {(role === "operator" || role === "insurance-operator") && (
              <Button
                className={"btn alignCenter flex noBackground"}
                withIcon
                icon={plus}
                type="button"
                onClick={addCard}
                text={t("addMedicalCard")}
              />
            )}
            {/* HIDE NOT TO SHOW IN RELEASE */}
            {showFavouriteButton && (
                <Button
                  className={favouriteButtonClassName}
                  icon={plus}
                  type="button"
                  loading={favouriteLoading}
                  onClick={handleSetFavourite(patientCard.id)}
                  text={favouriteButtonText}
                  disabled={!patient?.attendingDoctorAllow}
                />
              )}
          </div>
          <div className="doctorContentContainer">
            {addNewCard}
            {medicalcard}
            <Chat userRole={role} user={user} chat={chat} />
            {localStorage.getItem("isPersonalDoctor") !== "true" && (
              <>
                {role === "doctor" && (
                  params.patient ?
                    <ConsultationCard loading={loading} setLoading={setLoading} /> :
                    <div className="consultCard">
                      <p className="medCardTitle" style={{ marginBottom: '16px' }}>{t("consultationCard")}</p>
                      <div>{t("patientCardEmpty")}</div>
                    </div>
                )}
                {(role === "operator" || role === "insurance-operator") && (
                  (params.patient) ?
                    <NewConsultation
                      cards={cards}
                    /> :
                    <div className="newConsultationContainer">
                      <div className="newConsultationWrapper">
                        <div className="titleWrapper">
                          <div className={"setting"}>{t("registration")}</div>
                        </div>
                        
                        <div className="formWrapper">{t("patientCardEmpty")}</div>
                      </div>
                    </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ patients, user, chat, ...state }) => ({
  patients,
  attendingDoctor:
    state?.monitoring?.consultations?.currentConsultation?.patientCard
      ?.attendingDoctor,
  patient: patients && patients.patient,
  id:
    patients &&
    patients.patient &&
    patients.patient.patient &&
    patients.patient.patient.id,
  cards: patients && patients.cards && patients.cards.items,
  anamnesis: patients && patients.anamnesis && patients.anamnesis.items,
  patientCard: patients && patients.patient,
  currentConsultation: getCurrentConsultSelector(state),
  user,
  chat,
});

const enhance = compose(
  
  connect(mapStateToProps, {
    getAllPatients,
    setInitalValues,
    getUser,
    getThePatient,
    getPatientCards,
    getAllChatMessagesTemplates,
    getThePatientAppointments,
    getPatientCard,
    getThePatientAnamnesis,
    resetInitialValues,
    getCurrentConsultation,
    setNetworkError,
    addNotification,
    setPatientAsFavourite,
  })
);



export default enhance(Client);
