import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { store } from "../../..";
import { setFilterMode as setFilterModeConsultation } from "../../../redux/consultations/actions";
import { setFilterMode as setFilterModeEmployees } from "../../../redux/employees/actions";
import { resetInitialValues } from "../../../redux/formInitialValues/actions";
import { setFilterMode as setFilterModeShedule } from "../../../redux/shedule/actions";

import activeBanners from "../../../style/icons/Banners/active-banners-icon.svg";
import bannerSectionIcon from "../../../style/icons/Banners/banners-section-icon.svg";
import activeHome from "../../../style/icons/Home/home-active.svg";
import home from "../../../style/icons/Home/home.svg";
import plusActive from "../../../style/icons/PlusInCircleSider/plus-active.svg";
import plus from "../../../style/icons/PlusInCircleSider/plus.svg";
import activeClient from "../../../style/icons/Specialization/active-spec-icon.svg";
import client from "../../../style/icons/Specialization/specialization-section-icon.svg";
import activeShedule from "../../../style/icons/Shedule/active-shedule-icon.svg";
import sheduleSectionIcon from "../../../style/icons/Shedule/shedule-section-icon.svg";
import activeSpecialization from "../../../style/icons/Specialization/active-spec-icon.svg";
import specializationSectionIcon from "../../../style/icons/Specialization/specialization-section-icon.svg";
import activeStuff from "../../../style/icons/Staff/active-staff-icon.svg";
import stuffSectionIcon from "../../../style/icons/Staff/staff-section-icon.svg";
import activeSubscription from "../../../style/icons/Subscription/active-subscription-icon.svg";
import subscriptionSectionIcon from "../../../style/icons/Subscription/subscriptions-section-icon.svg";
import siderLogo from "../../../style/images/sider-logo.svg";
import companiesIcon from "../../../style/icons/Companies/companies-icon.svg"
import symptomsIcon from "../../../style/icons/Symptoms/symptomsIcon.svg"
import promocodesIcon from "../../../style/icons/Promocodes/promocodes-icon.svg"
import feedbacksIcon from "../../../style/icons/Feedbacks/feedback.svg"
import "./sider.scss";

const Sider = ({
  resetInitialValues,
  role,
}) => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const [forms, setForms] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    let forms = store.getState().form;
    setForms(forms);
  }, []);

  const resetAllForms = (forms) => {
    dispatch(setFilterModeShedule("all"));
    dispatch(setFilterModeConsultation("all"));
    dispatch(setFilterModeEmployees("all"));
    Object.keys(forms).map((el) => resetInitialValues(el));
  };
  
  return (
    <section className="siderContainer">
      <div className="sectionListContainer">
        <img
          className="siderLogo"
          src={siderLogo}
          alt="sider-logo"
          onClick={() => navigate("/")}
        />
        <div className={`sectionList ${role === "admin" ? "flexStart" : ""}`}>
          {/* if user === admin */}
          {role === "admin" && (
            <>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/admin/shedule");
                }}
              >
                <img
                  src={
                    pathname.includes("shedule")
                      ? activeShedule
                      : sheduleSectionIcon
                  }
                  alt="schedule-icon"
                  title={t("shifts")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/admin/employees?page=1&filter=all&specialties=");
                }}
              >
                <img
                  src={
                    pathname.includes("employees")
                      ? activeStuff
                      : stuffSectionIcon
                  }
                  alt="stuff-icon"
                  title={t("employees")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/admin/specialization");
                }}
              >
                <img
                  src={
                    pathname.includes("specialization")
                      ? activeSpecialization
                      : specializationSectionIcon
                  }
                  alt="specialization-icon"
                  title={t("specializations")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/admin/banners");
                }}
              >
                <img
                  src={
                    pathname.includes("banner")
                      ? activeBanners
                      : bannerSectionIcon
                  }
                  alt="banner-icon"
                  title={t("banners")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/admin/subscription");
                }}
              >
                <img
                  src={
                    pathname.includes("subscription")
                      ? activeSubscription
                      : subscriptionSectionIcon
                  }
                  alt="subscription-icon"
                  title={t("subscriptions")}
                />
              </div>
              <div
                className={`menu_item_link ${pathname.includes("promocodes") ? 'active' : ''}`}
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/admin/promocodes?page=1");
                }}
              >
                <img
                  src={promocodesIcon}
                  alt="promocodes-icon"
                  title={t("promocodes")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/admin/onboarding");
                }}
              >
                <img
                  src={
                    pathname.includes("onboarding")
                      ? activeSubscription
                      : subscriptionSectionIcon
                  }
                  alt="subscription-icon"
                  title={t("onboarding")}
                />
              </div>
              <div
                className={`menu_item_link symptoms ${pathname.includes("symptoms") ? 'active' : ''}`}
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/admin/symptoms");
                }}
              >
                <img
                  src={symptomsIcon}
                  alt="subscription-icon"
                  title={t("symptoms")}
                />
              </div>
              <div
                className={`menu_item_link companies ${pathname.includes("companies") ? 'active' : ''}`}
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/admin/companies?page=1");
                }}
              >
                <img
                  src={companiesIcon}
                  alt="companies-icon"
                  title={t("companies")}
                />
              </div>
              {/* HIDE NOT TO SHOW IN RELEASE */}
              <div
                className={`menu_item_link companies ${pathname.includes("feedbacks") ? 'active' : ''}`}
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/admin/feedbacks");
                }}
              >
                <img
                  src={feedbacksIcon}
                  alt="feedbacks-icon"
                  title={t("feedbacks")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/admin/push-notifications");
                }}
              >
                <img
                  src={
                    pathname.includes("push-notifications")
                      ? plusActive
                      : plus
                  }
                  alt="push-notifications-icon"
                  title={"Push notifications"}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/admin/hospitals");
                }}
              >
                <img
                  src={pathname.includes("hospitals") ? plusActive : plus}
                  alt="hospitals-icon"
                  title={"Hospitals"}
                />
              </div>

              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/admin/spec-mapping");
                }}
              >
                <img
                  src={
                    pathname.includes("spec-mapping") ? plusActive : plus
                  }
                  alt="mapping-icon"
                  title={"mapping"}
                />
              </div>
            </>
          )}
          {role === "division-admin" && (
            <>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/division-admin/shedule");
                }}
              >
                <img
                  src={
                    pathname.includes("shedule")
                      ? activeShedule
                      : sheduleSectionIcon
                  }
                  alt="schedule-icon"
                  title={t("shifts")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/division-admin/employees");
                }}
              >
                <img
                  src={
                    pathname.includes("employees")
                      ? activeStuff
                      : stuffSectionIcon
                  }
                  alt="stuff-icon"
                  title={t("employees")}
                />
              </div>
            </>
          )}
          {role === "insurance-admin" && (
            <>
              <div
                className={`menu_item_link ${pathname.includes("promocodes") ? 'active' : ''}`}
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/admin/promocodes");
                }}
              >
                <img
                  src={
                    pathname.includes("promocodes")
                      ? activeSubscription
                      : subscriptionSectionIcon
                  }
                  alt="promocodes-icon"
                  title={t("promocodes")}
                />
              </div>
            </>
          )}
          {role === "operator" && (
            <>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/operator/monitoring?page=1&period=All");
                }}
              >
                <img
                  className={"monitoringIcon"}
                  src={pathname.includes("monitoring") ? activeHome : home}
                  alt="monitoring-icon"
                  title={t("monitoring")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/operator/newClient");
                }}
              >
                <img
                  src={pathname.includes("newClient") ? plusActive : plus}
                  alt="client-icon"
                  title={t("client")}
                />
              </div>
            </>
          )}
          {/* if user === insurance-operator */}
          {role === "insurance-operator" && (
            <>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/insurance-operator/monitoring");
                }}
              >
                <img
                  className={"monitoringIcon"}
                  src={pathname.includes("monitoring") ? activeHome : home}
                  alt="monitoring-icon"
                  title={t("monitoring")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/insurance-operator/newClient");
                }}
              >
                <img
                  src={pathname.includes("newClient") ? activeClient : client}
                  alt="client-icon"
                  title={t("client")}
                />
              </div>
            </>
          )}
          {/* if user === doctor */}
          {role === "doctor" && (
            <>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/doctor/monitoring?page=1&period=All");
                }}
              >
                <img
                  src={pathname.includes("monitoring") ? activeHome : home}
                  alt="monitoring-icon"
                  title={t("monitoring")}
                />
              </div>
              {/* HIDE NOT TO SHOW IN RELEASE */}
              <div
                onClick={() => {
                  resetAllForms(forms);
                  navigate("/home/doctor/favorite-clients?page=1");
                }}
              >
                <img
                  src={
                    pathname.includes("favorite-clients")
                      ? activeClient
                      : client
                  }
                  alt="client-icon"
                  title={t("personalPatient")}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};
const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToprops = {
  resetInitialValues,
};

export default connect(mapStateToProps, mapDispatchToprops)(Sider);
