import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import FilterSelect from '../formComponent/FilterSelect/FilterSelect';
import Button from "../ui/Button/Button"
import plus from "../../../style/icons/PlusInCircle/plus.svg";
import plusNonActive from "../../../style/icons/PlusInCircle/plus-nonActive.svg";
import calendar from "../../../style/icons/Calendar/calendar-in-blue.svg";
import close from "../../../style/icons/Close/close-icon.svg";

import './NewConsultation.scss'
import { compose } from 'redux';
import Address from '../formComponent/Address/Address';
import DateTimePicker from '../formComponent/DateTimePicker/DateTimePicker';
import { canselConsultation, createConsultation, getCurrentConsultation, getRandomDoctor, getRandomDoctorInHospital, resetRandomDoctor, updateConsultation } from '../../../redux/consultations/actions';
import { getDistricts, getFreeDoctorTime, getFreeDoctorTimeInHospital, getProvinces, resetDates } from '../../../redux/shedule/actions';
import { getPatientsSelectCard } from '../../../redux/patients/selectors';
import { getSpecialtySelect } from '../../../redux/specializations/selectors';
import { getAllSpecializations } from '../../../redux/specializations/actions';
import { consultationFormats, getDateTimeFormat, paymentModes } from './formats';
import { getDistrictsSelector, getDoctorPeriodsSelect, getProvincesSelector } from '../../../redux/shedule/selectors';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { getCurrentConsultSelector } from '../../../redux/consultations/selectors';
import { getDoctorsByFilter, getDoctorsByHomeFilter, getDoctorsByHospitalFilter } from '../../../redux/employees/actions';
import { getDoctorsSelector, getRandomDoctorSelector } from '../../../redux/employees/selectors';
import { voidRequired } from '../../../helpers/validate';
import moment from "moment"
import {  } from 'react-router';
import Spinner from '../ui/Spinner/Spinner';
import Done from "./Done"
import { createAddressURLString } from '../../../helpers/functions';
import { getAllHospitals, getDepartmentsByHospitalId } from '../../../redux/hospitals/actions';
import { getDepartmentsSelector, getHospitalsSelector } from '../../../redux/hospitals/selectors';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';

const NewConsult = ({
  getCurrentConsultation,
  getDistricts,
  getProvinces,
  getAllSpecializations,
  updateConsultation,
  createConsultation,
  canselConsultation,
  getDoctorsByFilter,
  getFreeDoctorTime,
  getDoctorsByHomeFilter,
  getAllHospitals,
  getDepartmentsByHospitalId,
  getFreeDoctorTimeInHospital,
  getDoctorsByHospitalFilter,
  getRandomDoctorInHospital,
  getRandomDoctor,
  resetRandomDoctor,

  cards,
  specialties,
  provinces,
  districts,
  doctors,
  dates,
  hospitals,
  departments,
  randomDoctor,
  currentConsultation,

  fullScreenMode,
  formValues,
  reset,
  invalid,
  autofill,
  resetDates,
}) => {
  ///LOADERS FOR ALL FIELDS
  const [edit, setEdit] = React.useState(false)
  const [done, setDone] = React.useState(false)
  const [specialtyLoading, setSpecialtyLoading] = React.useState(true)
  const [districtsLoading, setDistrictsLoading] = React.useState(false)
  const [doctorsLoading, setDoctorsLoading] = React.useState(false)
  const [datesLoading, setDatesLoading] = React.useState(false)
  const [departmentsLoading, setDepartmentsLoading] = React.useState(false)
  const [hospitalLoading, setHospitalLoading] = React.useState(false)
  const [formLoading, setFormLoading] = React.useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate();
  const location = useLocation()
  const { t } = useTranslation()
  const consultId = searchParams.get("consultId");

  ///Request after load first time
  React.useEffect(() => {
    getAllSpecializations(50).finally(() => setSpecialtyLoading(false))
    getProvinces()
    return () => {
      reset()
      resetRandomDoctor()
      resetDates()
    }
  }, [])

  ////GET DISTRICTS AFTER SELECTED PROVINCE
  useUpdateEffect(() => {
    if (formValues?.province?.id) {
      setDistrictsLoading(true)
      getDistricts(formValues.province.id).finally(() => setDistrictsLoading(false))
    }
  }, [formValues?.province])

  ////AUTO FILL FORM WHEN EDITING CONSULTATION
  useUpdateEffect(() => {
    if (edit && currentConsultation) {
      Object.keys(currentConsultation).forEach((key) => {
        autofill(key, currentConsultation[key])
      })
    }
  }, [edit, currentConsultation])


  ////GET DOCTORS FOR ONLINE
  useUpdateEffect(() => {
    if(formValues?.format && formValues?.format?.num_type !== 0) return
    if (formValues?.specialization?.id && formValues?.format?.value) {
      setDoctorsLoading(true)
      getDoctorsByFilter([formValues.specialization.id]).finally(() => setDoctorsLoading(false))
    }
  }, [formValues?.specialization?.id, formValues?.format?.value])


  ///GET DOCTORS FOR HOME CONSULTATION
  useUpdateEffect(() => {
    if(formValues?.format?.num_type !== 1) return
    if (formValues?.district?.id) {
      setDoctorsLoading(true)
      const payload = {
        specialty: formValues.specialization.id,
        district: formValues.district.id,
        type: formValues.format.num_type,
      }
      getDoctorsByHomeFilter(payload)
        .finally(() => setDoctorsLoading(false))
    }
  }, [formValues?.district])

  ///GET DOCTORS FOR HOSPITAL CONSULTATION
  useUpdateEffect(() => {
    if(formValues?.format?.num_type !== 2) return
    if (formValues?.hospitalDepartments?.id) {
      setDoctorsLoading(true)
      const payload = {
        specialty: formValues.specialization.id,
        hospital: formValues.hospitalDepartments.id,
      }
      getDoctorsByHospitalFilter(payload)
        .finally(() => setDoctorsLoading(false))
    }
  }, [formValues?.hospitalDepartments])

  /////GET HOSPITAL DEPARTMENTS BY HOSPITAL
  useUpdateEffect(() => {
    if(formValues?.format?.num_type !== 2) return
    if (formValues?.hospitals?.id) {
      setDepartmentsLoading(true)
      const hospitalId = formValues.hospitals.id
      const specialtyId = formValues.specialization.id
      getDepartmentsByHospitalId(hospitalId, specialtyId)
        .finally(() => setDepartmentsLoading(false))
    }
  }, [formValues?.hospitals])


  ////GET DOCTOR DATE PERIODS IN HOSPITAL
  useUpdateEffect(() => {
    if (formValues?.format?.num_type !== 2) return
    if (formValues?.doctor?.id) {
      setDatesLoading(true)
      const payload = {
        specialty: formValues.specialization.id,
        hospital: formValues.hospitalDepartments.id,
        doctor: formValues.doctor.id,
      }
      getFreeDoctorTimeInHospital(payload)
        .finally(() => setDatesLoading(false))
    }
  }, [formValues?.doctor])


  ///GET DOCTOR DATE PERIODS
  useUpdateEffect(() => {
    if (formValues?.doctor?.id) {
      setDatesLoading(true)
      getFreeDoctorTime({
        doctor: formValues.doctor.id,
        specialty: formValues.specialization.id,
        type: formValues.format.num_type,
        district: formValues?.district?.id || null,
      })
        .finally(() => setDatesLoading(false))
    }
  }, [formValues?.doctor?.id])

  ///GET ALL HOSPITALS
  useUpdateEffect(() => {
    if (formValues?.format && formValues?.format?.num_type !== 2) return
    if (formValues?.specialization?.id && formValues?.format?.num_type === 2) {
      setHospitalLoading(true)
      getAllHospitals(formValues.specialization.id)
        .finally(() => setHospitalLoading(false))
    }
  }, [formValues?.format, formValues?.specialization])


  ////GET RANDOM DOCTOR PERIODS
  useUpdateEffect(() => {
    if(formValues?.format?.num_type === 2) return
    if (formValues?.doctor?.randomDoc) {
      setDatesLoading(true)
      const specialtyId = formValues.specialization.id
      const type = formValues.format.num_type
      const district = formValues?.district?.id || null
      getRandomDoctor(specialtyId, type, district)
        .finally(() => setDatesLoading(false))
    }
  }, [formValues?.doctor])

  ////GET RANDOM DOCTOR PERIODS IN HOSPITAL
  useUpdateEffect(() => {
    if (formValues?.format && formValues.format.num_type !== 2) return
    if (formValues?.doctor?.randomDoc) {
      setDatesLoading(true)
      const departmentId = formValues.hospitalDepartments.id
      const specialtyId = formValues.specialization.id
      getRandomDoctorInHospital(departmentId, specialtyId)
        .finally(() => setDatesLoading(false))
    }
  }, [formValues?.doctor])


  ///SETTING RANDOM DOCTOR TO THE FORM
  useUpdateEffect(() => {
    if (randomDoctor?.id) {
      autofill("doctor", randomDoctor)
    }
  }, [randomDoctor])


  //// BOOK CONSULTATION FOR 3 DIFFERENT TYPES
  const createConsult = () => {
    setFormLoading(true)
    let payload = {}
    const specialtyId = formValues.specialization.id;

    switch (formValues.format.num_type) {
      case 0: ///// 0 MEANS ONLINE CONSULTATION
        payload = {
          appointment: {
            method: formValues.format.method,
            start: getDateTimeFormat(formValues.reservationDate),
            doctor: formValues.doctor.id,
            patientCard: formValues.card.id,
            paymentType: 2, // FOR FREE
          }
        }
        break;
      case 1: ///// 1 MEANS HOME CONSULTATION
        payload = {
          appointment: {
            method: formValues.format.method,
            start: getDateTimeFormat(formValues.reservationDate),
            doctor: formValues.doctor.id,
            patientCard: formValues.card.id,
            paymentType: 2, // FOR FREE
            address: {
              province: formValues.province.id,
              district: formValues.district.id,
              ...formValues.address,
            },
          }
        }
        break;
      case 2: ///// 2 MEANS HOSPITAL CONSULTATION
        const dateUTC = moment.utc(getDateTimeFormat(formValues.reservationDate)).format().slice(0, -1);
        const id = dates.find(({ start }) => start.includes(dateUTC)).id;
        payload = {
          appointment: {
            method: formValues.format.method,
            hospital: formValues.hospitalDepartments.id,
            doctor: formValues.doctor.id,
            integrationSeance: id,
            patientCard: formValues.card.id,
            paymentType: 1, // NEED TO PAY
          }
        }
        break;
      default: break
    }

    createConsultation(specialtyId, payload)
      .then(() => setDone(true))
      .finally(() => setFormLoading(false))
  }

  const updateConsult = () => {
    setFormLoading(true)
    let payload = {}
    switch (formValues.format.num_type) {
      case 0:
        payload = {
          appointment: {
            start: getDateTimeFormat(formValues?.reservationDate),
            doctor: formValues.doctor.id
          }
        }
        break;
      case 1:
        payload = {
          appointment: {
            start: getDateTimeFormat(formValues?.reservationDate),
            doctor: formValues.doctor.id,
            address: {
              ...formValues.address,
              province: formValues.province.id,
              district: formValues.district.id,
            }
          }
        }
        break;
      default: break;
    }
    updateConsultation(consultId, payload)
      .then(() => {
        getCurrentConsultation(consultId)
          .then((res) => {
            setTimeout(() => {
              if(formValues.format.num_type === 0) return
              if(formValues.format.num_type === 2) return
              const addressString = createAddressURLString(res.entity)
              navigate(`${location.pathname}?user=${currentConsultation.patient.userId}&consultId=${currentConsultation.id}&${addressString}`)
            }, 0)
          })
        setFormLoading(false)
        setDone(true)
      })
      .finally(() => setFormLoading(false))
  }

  const cancelConsult = () => {
    const confirm = window.confirm(t("cancelConfirm"));
    if (confirm) {
      setFormLoading(true)
      canselConsultation({ id: consultId })
        .then(() => {
          getCurrentConsultation(consultId);
          setFormLoading(false)
        })
        .finally(() => setFormLoading(false))
    }
  }

  const formSubmit = (e) => {
    e.preventDefault()
    if (!edit) createConsult()
    if (edit) updateConsult()
  }
  
  const handleToggleEdit = () => {
    if (edit) {
      setEdit(false)
      reset()
      resetRandomDoctor()
      resetDates()
    } else {
      setEdit(true)
    }
  }

  const handleSetDone = () => {
    setDone(false)
    setEdit(false)
    reset()
    resetRandomDoctor()
    resetDates()
  }

  const handleResetForm = () => {
    reset()
    resetRandomDoctor()
    resetDates()
  }


  ///MARKERS FOR DISABLING FIELDS
  const isDistrictsReady = districts.length > 0 && formValues?.province?.id
  const isCardsReady = cards.length > 0
  const isSpecialtiesReady = specialties.length > 0
  const isAddressReady = !!formValues?.district || formValues?.district
  const isDoctorsReady = (doctors.length > 0 && formValues?.format?.value) || formValues?.doctor?.id
  const isDatesReady = (dates.length > 0 && formValues?.doctor?.id) || formValues?.reservationDate 
  const isPaymentReady = !formValues?.reservationDate
  const isAbleToEdit = currentConsultation?.status === "new" && currentConsultation?.type !== 2
  const isAbleToCancel = isAbleToEdit || currentConsultation?.status === "filled"
  const isDepartmentsReady = formValues?.hospitals?.id
  const isResetingEnabled = formValues && Object.keys(formValues).length && !edit
  //SUCCESS INFO AFTER CREATING OR UPDATING CONSULTATION
  if (done) return <Done handleSetDone={handleSetDone}/>
  
  return (
    <div className="newConsultationContainer">
      <div className={`newConsultationWrapper ${fullScreenMode ? "withFullScreenVideo" : ""}`}>
        {formLoading && (
          <div className="absoluteCenter">
            <Spinner/>
          </div>
        )}
        <div className="titleWrapper">
          <div className={"setting"}>{edit ? t("newScheduleFilterFormTitleEdit") : t("registration")}</div>
          {isResetingEnabled && (
            <div onClick={handleResetForm} className="resetButtonBox">
              <div className="resetText">{t("reset")}</div>
              <img
                style={{ margin: "0px" }}
                className="resetForm"
                title="Очистить форму"
                src={close}
                alt="closeIcon"
              />
            </div>
          )}
        </div>
        
        <div className="formWrapper">
          <Form onSubmit={formSubmit}>
            <div className={`fieldsWrapper ${formLoading ? "loading" : ""}`}>
              <Field
                name="card"
                component={FilterSelect}
                placeholder={t("placeholderMedCard")}
                options={cards}
                withIcon
                icon={plus}
                withPreview
                previewIcon={plus}
                withIconInOptions
                disabledIcon={plusNonActive}
                disabled={!isCardsReady || edit}
                buttonLoading={!isCardsReady}
                validate={[voidRequired]}
                // defaultValue={edit ? current.Consultation?.card : null}
              />
              <Field
                name="specialization"
                component={FilterSelect}
                placeholder={t("placeholderSpecialty")}
                options={specialties}
                withIcon
                icon={plus}
                withPreview
                multiple={false}
                previewIcon={plus}
                withIconInOptions
                buttonLoading={specialtyLoading}
                disabledIcon={plusNonActive}
                disabled={!isSpecialtiesReady || edit}
                validate={[voidRequired]}
              />
              <Field
                name="format"
                component={FilterSelect}
                placeholder={t("filterTypeOfService")}
                options={consultationFormats}
                withIcon
                icon={plus}
                withPreview
                previewIcon={plus}
                withIconInOptions
                disabled={edit}
                disabledIcon={plusNonActive}
                validate={[voidRequired]}
              />
              {formValues?.format?.value === "TYPE_HOME" && (<>
                <Field
                  name="province"
                  component={FilterSelect}
                  placeholder={t("province")}
                  options={provinces}
                  icon={plus}
                  withIcon
                  withPreview
                  previewIcon={plus}
                  withIconInOptions
                  disabledIcon={plusNonActive}
                  disabled={!provinces}
                  validate={[voidRequired]}
                />
                <Field
                  name="district"
                  buttonLoading={districtsLoading}
                  component={FilterSelect}
                  placeholder={t("district")}
                  options={districts}
                  icon={plus}
                  withIcon
                  withPreview
                  withIconInOptions
                  previewIcon={plus}
                  disabledIcon={plusNonActive}
                  disabled={!isDistrictsReady}
                  validate={[voidRequired]}
                />
                <Field
                  name="address"
                  component={Address}
                  placeholder={t("inputAddress")}
                  label={t("inputAddress")}
                  blur={false}
                  disabled={!isAddressReady}
                  validate={[voidRequired]}
                  defaultValues={edit ? currentConsultation.address : null}
                  onlyTextFields
                  required
                />
              </>)}
              {formValues?.format?.value === "TYPE_HOSPITAL" && (<>
                <Field
                  name="hospitals"
                  component={FilterSelect}
                  placeholder={t("hospitals")}
                  options={hospitals}
                  withIcon
                  icon={plus}
                  withPreview
                  buttonLoading={hospitalLoading}
                  previewIcon={plus}
                  withIconInOptions
                  disabledIcon={plusNonActive}
                  validate={[voidRequired]}
                />
                <Field
                  name="hospitalDepartments"
                  component={FilterSelect}
                  placeholder={t("hospitalDepartments")}
                  options={departments}
                  withIcon
                  icon={plus}
                  withPreview
                  previewIcon={plus}
                  disabled={!isDepartmentsReady}
                  withIconInOptions
                  buttonLoading={departmentsLoading}
                  disabledIcon={plusNonActive}
                  validate={[voidRequired]}
                />
              </>)}
              <Field
                name="doctor"
                component={FilterSelect}
                placeholder={t("doctor")}
                options={doctors}
                withIcon
                icon={plus}
                withPreview
                previewIcon={plus}
                withIconInOptions
                disabled={!isDoctorsReady}
                disabledIcon={plusNonActive}
                buttonLoading={doctorsLoading}
                validate={[voidRequired]}
              />
              <Field
                name="reservationDate"
                component={DateTimePicker}
                options={dates}
                placeholder={t("bookDate")}
                withIcon
                refresh={true}
                icon={plus}
                disabled={!isDatesReady}
                withPreview={formValues?.reservationDate}
                previewIcon={calendar}
                disabledIcon={plusNonActive}
                buttonLoading={datesLoading}
                defaultValue={edit ? currentConsultation?.reservationDate : null}
                validate={[voidRequired]}
              />
              <Field
                name="paymentMode"
                component={FilterSelect}
                placeholder={t("paymentType")}
                options={paymentModes}
                withIcon
                icon={plus}
                withPreview
                previewIcon={plus}
                withIconInOptions
                disabled={isPaymentReady || edit}
                disabledIcon={plusNonActive}
                validate={[voidRequired]}
              />
            </div>
            <div className={"buttonWrapper"}>
              {!invalid && !edit && (
                <div className="price">
                  <div className="cost">{t("priceTitle")}</div>
                  <div className="cost">0₴</div>
                </div>
              )}
              {!edit && (
                <Button
                  type="submit"
                  text={t("arrangeButton")}
                  className={`btn btn-submit ${!invalid ? "active" : "disactive"}`}
                  disabled={invalid || formLoading || !formValues?.card}
                />
              )}
              {consultId && (<>
                {!edit && (
                  <Button
                    className="btn btn-change"
                    type="button"
                    text={t("changeButton")}
                    disabled={!isAbleToEdit || formLoading}
                    onClick={handleToggleEdit}
                    loading={!currentConsultation?.status}
                  />
                )}
                {edit && (
                  <Button
                    className="btn btn-change"
                    type="submit"
                    text={t("saveButton")}
                    loading={!currentConsultation?.status}
                    disabled={formLoading}
                  />
                )}
                {!edit && (
                  <Button
                    className="btn btn-cancel"
                    danger
                    loading={!currentConsultation?.status}
                    type="button"
                    onClick={cancelConsult}
                    text={t("cancelOfConsultation")}
                    disabled={!isAbleToCancel || formLoading}
                  />
                )}
                {edit && (
                  <Button
                    className="btn btn-cancel"
                    danger
                    loading={!currentConsultation?.status}
                    type="button"
                    text={t("cancelButton")}
                    disabled={formLoading}
                    onClick={handleToggleEdit}
                  />
                )}
              </>)}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  formValues: getFormValues("NewConsultationForm")(state),
  cards: getPatientsSelectCard(state),
  specialties: getSpecialtySelect(state),
  provinces: getProvincesSelector(state),
  districts: getDistrictsSelector(state),
  currentConsultation: getCurrentConsultSelector(state),
  doctors: getDoctorsSelector(state),
  dates: getDoctorPeriodsSelect(state),
  hospitals: getHospitalsSelector(state),
  departments: getDepartmentsSelector(state),
  randomDoctor: getRandomDoctorSelector(state),
})

const mapDispatchToProps = {
  getCurrentConsultation,
  getDistricts,
  getProvinces,
  updateConsultation,
  createConsultation,
  canselConsultation,
  getAllSpecializations,
  getDoctorsByFilter,
  getFreeDoctorTime,
  getDoctorsByHomeFilter,
  resetDates,
  getAllHospitals,
  getDepartmentsByHospitalId,
  getFreeDoctorTimeInHospital,
  getDoctorsByHospitalFilter,
  getRandomDoctorInHospital,
  getRandomDoctor,
  resetRandomDoctor,
}

const NewConsultationCard = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "NewConsultationForm", enableReinitialize: true })
);

export default NewConsultationCard(NewConsult);
