import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ErrorBoundary from "./views/components/ErrorBoundary/ErrorBoundary";
import { configureStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import App from "./routes/index";
import "./i18n";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

import "@csstools/normalize.css";

import "./index.scss";
import NotificationProvider from "./views/components/NotificationProvider/NotificationProvider";
import { createRoot } from 'react-dom/client';

// Sentry.init({
//   dsn:
//     "https://789a0a5aa26c4e6bb55a06dbad3e9006@o340358.ingest.sentry.io/5420010",
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

export const store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ErrorBoundary>
        <NotificationProvider>
          <App />
        </NotificationProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>
);
