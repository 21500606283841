import React, {useState} from 'react';
import {Modal} from "@material-ui/core";
import './ChangeLoginModal.scss';
import {useTranslation} from "react-i18next";
import PhoneInput from "react-phone-input-2";
import PatientApi from '../../../api/patients';
import CloseIcon from "@material-ui/icons/Close";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const ChangeLoginModal = ({person, userId, userPhone}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmInfo, setConfirmInfo] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [disabledNumber, setDisabledNumber] = useState(false);
  const navigate = useNavigate()


  const [phone, setPhone] = useState('');

  const onChangeHandler = (value, data) => {
    setPhone(value);
  }
  const {t} = useTranslation();
  const onConfirmHandler = () => {
    setDisabled(true);
    setDisabledNumber(true);
    const isSamePhone = userPhone === `+${phone}`;
    if(userId && !isSamePhone){
      PatientApi.updatePatientLogin(`+${phone}`, userId, !!confirmInfo).then(r => {
        if (r.newPatientCard || r.oldUser || r.newUser) {
          setDisabled(false);
          setConfirmInfo(r);
        }else{
          if(!r?.message){
            alert(t('changeLoginSuccess'));
            closeHandler();
            navigate("/home/operator/monitoring?page=1&period=All");
          }else{
            setDisabled(false);
            setDisabledNumber(false);
          }
        }
      }).catch(e => {
      })
    }else {
      if(isSamePhone){
        alert(t('Ошибка. введён тот же номер!'))
        setDisabled(false);
        setDisabledNumber(false);
      }else {
        alert(t('Ошибка. Логин пользователя не изменен, не найден айди'))
      }
    }

  }

  const openHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  }
  const closeHandler = () => {
    setIsOpen(false);
    setConfirmInfo(null)
    setPhone('');
    setDisabled(false);
    setDisabledNumber(false);
  }

  let newPatronymic = ''

  if(confirmInfo?.newPatientCard?.patronymic){
    newPatronymic = confirmInfo?.newPatientCard?.patronymic
  }

  const fullName = confirmInfo?.newPatientCard?.firstName ?  confirmInfo?.newPatientCard?.firstName + ' ' + confirmInfo?.newPatientCard?.lastName + ' ' + newPatronymic : 'Дані відсутні'

  return (
    <div>
      <button className='changeLogin' onClick={openHandler}>
        {t('changeLoginButton')}
      </button>
      <Modal
        open={isOpen}
        onClose={closeHandler}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <div className='change-login-modal_body'>
          <div className="change-login-modal_content">
            <div className='change-login-modal_content_header'>
              <span>{t('changeLoginHeader')}</span>
              <CloseIcon onClick={closeHandler}/>
            </div>
            <div className='change-login-modal_content_body'>
              <div className='change-login-modal_scroll'>
                <div className='change-login-modal_current'>
                  <span className='change-login-modal_current_text'>{person?.firstName} {person?.lastName} {person?.patronymic}</span>
                  <span className='change-login-modal_current_text'>{t('changeLoginCurrent')}{userPhone}</span>
                </div>
                <div className='change-login-modal_new'>
                  <span>{t('changeLoginPhone')}:</span>
                  <PhoneInput
                    disabled={disabledNumber}
                    country={'ua'}
                    inputProps={{name: 'phone'}}
                    onChange={onChangeHandler}
                    placeholder={"+380XXXXXXX"}
                  />
                </div>
                {confirmInfo && <div className='change-login-modal_new'>
                  <span className='change-login-modal_current_text'><b>{t('changeLoginWarning')}:</b> {fullName} </span>
                </div>}
              </div>
              <div className='change-login-modal_control'>
                <button disabled={disabled} className='change-login-modal_button_success'
                        onClick={onConfirmHandler}>{confirmInfo ? t('changeLoginButtonMerge') : t('changeLoginButtonSuccess')}</button>
                <button className='change-login-modal_button_cancel' onClick={closeHandler}>{t('changeLoginButtonDecline')}</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ patients }) => ({
  userPhone: patients?.patient?.patient?.phone || '',
});

export default connect(mapStateToProps)(ChangeLoginModal);