import React from "react";
import { connect } from "react-redux";
import { Route, Navigate } from "react-router-dom";

const GuestRoute = ({ component: Component, user, isLogged, ...rest }) => {

  if(!isLogged){
    return <Component {...rest} />;
  }
  return <Navigate to="/" />;
};

const mapStateToProps = ({ user }) => ({
  user,
  isLogged: user && user.isLogged,
});

export default connect(mapStateToProps)(GuestRoute);
