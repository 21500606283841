import React, { useEffect, useState } from "react";
import Button from "../ui/Button/Button";

import shevronDown from "../../../style/icons/ShevronDown/shevron-down.svg";
import docIcon from "../../../style/icons/DocIcon/doc.svg";
import operatorIcon from "../../../style/icons/Operator/operator-blue.svg";
import close_icon from "../../../style/icons/Chat/close-icon.svg";

import "./userTool.scss";

import Notification from "../ui/Notification/Notification";
import { connect, useDispatch } from "react-redux";
import DropDown from "../ui/DropDown/DropDovn";
import Select from "../formComponent/Select/Select";

import { signOut } from "../../../redux/auth/action";
import {
  toggleModal,
  getNotificationsList,
} from "../../../redux/notifications/actions";
import { setFilterMode as setFilterModeShedule } from "../../../redux/shedule/actions";
import { setFilterMode as setFilterModeConsultation } from "../../../redux/consultations/actions";

import { getFormValues, reduxForm } from "redux-form";
import { compose } from "redux";
import NotificationsModal from "../NotificationsModal/NotificationsModal";
import { useTranslation } from "react-i18next";
import { resources } from "../../../i18n";
import i18next from "i18next";
import moment from "moment";
import { changeLang } from "../../../redux/lang/action";
import {useLocation} from "react-router-dom"

const UserToolCard = ({
  user,
  signOut,
  notifications,
  toggleModal,
  getNotificationsList,
  readNotification,
  changeLang,
}) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { firstName, lastName, roles } = user || {};
  const status = ["Доступен", "Отошел", "Занят", "Офлайн"];
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    getNotificationsList();
  }, []);

  if (notifications.openModal) {
    return (
      <div className="userToolContainer openedNotifications">
        <span className="not">{t("notifications")}</span>
        <img
          src={close_icon}
          alt="Закрыть"
          onClick={() => toggleModal(false)}
        />

        <NotificationsModal
          list={notifications.list}
          closeModal={() => toggleModal(false)}
        />
      </div>
    );
  }

  let storage_ids = [];
  if (localStorage.getItem("nots-ids")) {
    storage_ids = JSON.parse(localStorage.getItem("nots-ids"));
  }

  function resetAllFilters() {
    dispatch(setFilterModeShedule("all"));
    dispatch(setFilterModeConsultation("all"));
  }

  const languageChangeHandler = (e) => {
    console.log(e);
    moment.locale(e);
    changeLang(e);
    i18next.changeLanguage(e);
    window.location.reload();
  };
  return (
    <div className="userToolContainer" onClick={() => setActive(true)}>
      {/* avatar img will be came from back */}
      <DropDown
        prev={
          <>
            <div className="avatar">
              <img
                src={
                  user.photoUrl ||
                  (roles?.length > 0 &&
                  roles.find(
                    (el) =>
                      el === "ROLE_OPERATOR" || el === "ROLE_INSURANCE_OPERATOR"
                  )
                    ? operatorIcon
                    : docIcon)
                }
                alt=""
              />
            </div>
            {firstName && lastName && (
              <div className="name">{`${lastName} ${firstName}`}</div>
            )}
            <div className="options">
              <img src={shevronDown} alt="" />
            </div>
          </>
        }
      >
        <div className={"dropWindow"}>
          {/* <div className={"dropContent"}> */}
          {/* <div className={"status"}>Статус</div>
            <div className={"choiceOptions"}>
              {status.map((item) => (
                <div
                  key={item}
                  className="choiceOption"
                  onClick={() => {
                    setSelectedOption(item);
                  }}
                >
                  <div className={"circleBlu"}>
                    <div
                      className={
                        selectedOption === item
                          ? "circleWhite active"
                          : "circleWhite"
                      }
                    ></div>
                  </div>
                  <span>{item}</span>
                </div>
              ))}
            </div> */}
          {/* <div className={"status channels"}>Каналы</div> */}
          {/* <div className={"inputarea"}> */}
          {/* <img src={shevronDown} alt="chevronDown" /> */}
          {/* </div> */}

          <Select
            onChange={languageChangeHandler}
            options={Object.keys(resources).filter(key => key !== 'ru')}
            placeholder={t("changeLang")}
            // .map(el => ({ title: el }))}
          />

          <Button
            className="btn noBackground"
            danger
            text={t("exitButton")}
            onClick={() => {
              signOut();
              resetAllFilters();
            }}
          />
        </div>
      </DropDown>

      {(pathname.includes("/operator/") ||
        pathname.includes("/insurance-operator/")) && (
        <Notification
          toggleModal={toggleModal}
          getNotificationsList={getNotificationsList}
          unsearchNotifications={notifications.unreadedCount}
        />
        )}
      {pathname.includes('/doctor') && (
        <Notification
          onlyConnect
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ user, notifications, language }, state) => ({
  user: user || {},
  notifications,
  formValues: getFormValues("userToolForm")(state),
  initialValues: state.formInitialValues,
  language,
});

const UserTool = compose(
  connect(mapStateToProps, {
    signOut,
    toggleModal,
    getNotificationsList,
    changeLang,
  }),
  reduxForm({ form: "userToolForm", enableReinitialize: true })
);
export default UserTool(UserToolCard);
