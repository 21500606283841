import React from "react";

import "./header.scss";

import Search from "../Search/Search";
import UserTool from "../UserTool/UserTool";
import DoctorsListBySearch from "../DoctorsListBySearch/DoctorsListBySearch";
import { useParams,  } from "react-router-dom";
import ProgressModal from "../ProgressModal/ProgressModal";


const Header = ({role}) => {
  return (
    <>
      <div
        className={`headerContainer ${role === "operator" ? "operator" : ""}`}
      >
        <Search role={role} />
        <ProgressModal/>
        <UserTool />
      </div>
      <DoctorsListBySearch role={role} />
      {/* <PatientsSearchList role={role} /> */}
    </>
  );
};

export default Header;
