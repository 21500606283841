import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useNavigate,  } from "react-router-dom";
import { compose } from "redux";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import api from "../../../api";
import { signOut } from "../../../redux/auth/action";

import Input from "../../components/formComponent/Input/Input";
import Button from "../../components/ui/Button/Button";

import "./password.scss";

const SetPassword = ({
  formValues = {},
  dirty,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const formSubmit = (e) => {
    e.preventDefault();
    handleSubmit((values) => {
      api.user
        .setPass({
          password: values.password,
          passwordToken: location.search.split("?token=")[1],
        })
        .then((value) => {
          if (Boolean(localStorage.getItem("token"))) {
            signOut();
            navigate("/");
            window.location.reload();
          } else {
            navigate("/");
          }
        });
    })();
  };

  return (
    <div className="background">
      <div className="wrappPasswordForm">
        <h1>{t("createPassword")}</h1>
        <Form onSubmit={formSubmit} className="passwordForm">
          <div className="formContent">
            <Field
              name="password"
              type="password"
              component={Input}
              placeholder={t("placeholderPassword")}
            />
            <Field
              name="passwordConfirm"
              type="password"
              component={Input}
              placeholder={t("placeholderRepeatPassword")}
            />
          </div>
          <div className="formFooter">
            <Button
              disabled={
                !dirty || formValues.password !== formValues.passwordConfirm
              }
              className="btn"
              type="submit"
              text={t("confirmButton")}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

const mapDispatchToProps = {};
const mapStateToProps = (state) => ({
  formValues: getFormValues("password")(state),
});
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "password" }),
);

export default enhance(SetPassword);
