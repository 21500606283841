import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { MessagesList, ChatTools, CallTools } from "./components";
import { connect, useSelector } from "react-redux";
import { PORT_SUPPORT_SOCKET } from "../../../api";
import {  useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getConsultationAnamnesis,
  getCurrentConsultation,
  resetCurrentConsultation,
} from "../../../redux/consultations/actions";
import { setNetworkError } from "../../../redux/networkError/actions";
import {
  stopChatCall,
  sendChatMessage,
  getChatHistory,
  disconnectCallWS,
  mutedAudioChatCall,
  mutedVideoChatCall,
  clearHistoryChat,
  getChatHistorySocket,
  leaveChatRoom,
} from "../../../redux/chat/actions";
import { SIGNALING_SERVER, PORT_CHAT_SOCKET } from "../../../api/index";
import "react-circular-progressbar/dist/styles.css";
import "./Chat.scss";

const Chat = ({
  appointments,
  getCurrentConsultation,
  getConsultationAnamnesis,
  cancelOfConsultationFlagSet,
  setNetworkError,
  patientCard,
  doctor,
  bothInChat,
  bothInCall,
  stopChatCall,
  sendChatMessage,
  getChatHistory,
  historyChat,
  disconnectCallWS,
  mutedAudioChatCall,
  mutedVideoChatCall,
  currentConsultation,
  isVideoCall,
  isAudioCall,
  resetCurrentConsultation,
  getChatHistorySocket,
  leaveChatRoom,
  ...props
}) => {
  const { t } = useTranslation();
  const docSpecialty = useSelector(
    (state) =>
      (state.monitoring &&
        state.monitoring.consultations &&
        state.monitoring.consultations.currentConsultation &&
        state.monitoring.consultations.currentConsultation.specialty) ||
      ""
  );

  const [messageValue, setMessageValue] = useState("");
  const [status, setStatus] = useState(true);
  const [messageIdEdit, setMessageIdEdit] = useState(null);
  const params = useParams();
  const [searchParams] = useSearchParams()
  const messageListRef = React.useRef(null);
  const isAttendingDoctor = Boolean(
    window.localStorage.getItem("isPersonalDoctor")
  );
  const patientID = searchParams.get("user");
  const consultId = searchParams.get(
    "consultId"
  );
  const isPersonalDoctor = Boolean(
    searchParams.get("isPersonalDoctor")
  );
  const role = isPersonalDoctor ? "personalDoctor" : props.userRole;

  useEffect(() => {
    return () => {
      leaveChatRoom();
      resetCurrentConsultation();
    };
  }, []);

  const updateChatHistory = (page = 0) => {
    console.log('updateChatHistory', page);
    getChatHistorySocket({
      userId: isPersonalDoctor ? params.card : patientID,
      consultationId: consultId,
      doctorId: doctor?.doctor?.id,
      page,
      role: role,
    });
  };

  useEffect(() => {
    getCurrentConsultation(consultId);
  }, [consultId]);

  useEffect(() => {
    const _status = (currentConsultation && currentConsultation.status) || null;
    setStatus(_status);
  }, [currentConsultation]);

  const handleScrollBottom = (scrollTop = 999999) => {
    messageListRef.current.scrollTo({
      top: scrollTop,
    });
  };

  return (
    <div className={`doctorChat ${isAttendingDoctor ? " favouriteChat" : ""}`}>
      <CallTools
        role={role}
        // doctor={props.user.doctor}
        status={status}
        isVideoCall={isVideoCall}
        patientID={patientID}
        consultId={consultId}
        docSpecialty={docSpecialty}
        doctor={doctor}
        currentConsultation={currentConsultation}
      />

      <MessagesList
        role={role}
        t={t}
        videoCall={isVideoCall}
        userId={patientID}
        setMessageValue={setMessageValue}
        messageListRef={messageListRef}
        setMessageEdit={setMessageIdEdit}
        updateChatHistory={updateChatHistory}
        handleScrollBottom={handleScrollBottom}
        consultId={consultId}
        status={status}
      />
      <ChatTools
        cardId={params.card}
        messageValue={messageValue}
        setMessageValue={setMessageValue}
        setMessageIdEdit={setMessageIdEdit}
        status={status}
        handleScrollBottom={handleScrollBottom}
        t={t}
        messageIdEdit={messageIdEdit}
        patientID={patientID}
        doctorId={doctor?.doctor?.id}
        consultId={consultId}
        videoCall={isVideoCall}
        isAttendingDoctor={isPersonalDoctor}
        currentConsultation={currentConsultation}
        role={role}
      />
    </div>
  );
};

const mapStateToProps = ({ patients, user, monitoring, chat }) => ({
  historyChat: chat.historyChat.items,
  bothInChat: chat.countMembersInRoom,
  isVideoCall: chat.isVideoCall,
  isAudioCall: chat.isAudioCall,
  patientCard: patients && patients.patient,
  appointments: patients && patients.appointments,
  doctor: user,
  currentConsultation: monitoring?.consultations?.currentConsultation,
});

export default connect(mapStateToProps, {
  getCurrentConsultation,
  getConsultationAnamnesis,
  setNetworkError,
  stopChatCall,
  sendChatMessage,
  getChatHistory,
  disconnectCallWS,
  mutedAudioChatCall,
  mutedVideoChatCall,
  resetCurrentConsultation,
  clearHistoryChat,
  getChatHistorySocket,
  leaveChatRoom,
})(Chat)
