import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllHospitals } from "../../../redux/hospitals/actions";
import Spinner from "../../components/ui/Spinner/Spinner";

import "./Hospitals.scss";

const Hospitals = ({ getAllHospitals, hospitals = [] }) => {
  const navigate = useNavigate();
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAllHospitals().then(() => setLoading(false));
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="hospitalsListWrapper">
            <p className="pageTitle">{t("hospitals")}</p>
          <div className="hospitalItemsContainer">
            {hospitals && hospitals.length ? (
              hospitals.map((hospital) => {
                return (
                  <div
                    className="hospitalItem"
                    key={hospital.id}
                    onClick={() =>
                      navigate(
                        `/home/admin/hospitals/division/${hospital.id}`
                      )
                    }
                  >
                    <p>{hospital.title}</p>
                    <p>{hospital.addressText}</p>
                  </div>
                );
              })
            ) : (
              <p>EmptyList</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ hospitals }) => ({
  hospitals: hospitals && hospitals.defaultHospitals && hospitals.defaultHospitals.items,
});

export default connect(mapStateToProps, { getAllHospitals })(Hospitals);
